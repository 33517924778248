import React from 'react';

import {
    retrieveMerchantBillingInfo,
    setMerchantAuthToken,
    getMerchantSubscriptionPlan, logout, getSupportedCountries,
} from './Utilities/DataManager';

import { CardElement } from "./_library/CardElement"

import Footer from "./_library/Footer";
import Header from "./_library/Header";
import {getAuthToken,storeAuthToken} from "./Utilities/StorageManager";

import {Table} from "react-bootstrap";
import IdleTimer from 'react-idle-timer'

class MerchantPayment extends React.Component {

    constructor(props) {
        super(props);

        this.idleTimer = null
        this.onIdle = this._onIdle.bind(this)

        this.state = {
            pipioJSON: undefined,
            merchant_id: '',
            plan_code: '',
            account_code: "",
            currentBillingInfo: undefined,
            plan: undefined,
            nobillinginfo: false
        };

    }

    seconds = process.env.REACT_APP_IDLE_TIMEOUT ? process.env.REACT_APP_IDLE_TIMEOUT : 180
    stopIdleTimer = false

    componentDidMount(){

        if (this.props.history.location.state) {

            if (this.props.history.location.state.isRegistration)
                this.stopIdleTimer = true

            let pipioJSON = this.props.history.location.state.pipioJSON;

            //let pipioJSON = {"merchant_id" : 19894, "business_name":"1974","address_line_1":"1200 BLUE GUM LN","address_line_2":"","city":"NEWPORT BEACH","state":"California",country: "United States", "zipcode":"92660","phone":"949-208-7920","website":"","facebook":"","business_description":"","password":"12345678","email":"smalbois+pipio4@gmail.com","agreed":true,"subscription":2,"category":3,"sub_categories":[],"longitude":-117.9019002,"latitude":33.6242676,"subscription_expiration":"2020-02-11T18:15:49.039Z"}
            this.setState({
                history: this.props.history,
                pipioJSON,
                merchant_id: pipioJSON.merchant_id,
                plan_code: pipioJSON.subscription
            })

            setMerchantAuthToken(getAuthToken())
            retrieveMerchantBillingInfo(pipioJSON.merchant_id, response => {
                if (response && response !== 'error') {
                    if (!response.address.country) {
                            // NO BILLING COUNTRY ON RECURLY SIDE - LIKELY WIPED OUT.
                            getSupportedCountries(response2 => {
                                if (response2 && response2 !== 'error') {
                                    this.setState({currentBillingInfo: response, currentCountry: response2[pipioJSON.country], nobillinginfo: true})
                                }
                            })
                    } else if (this.props.history.location.state.currentCountry === undefined) {
                            getSupportedCountries(response2 => {
                                if (response2 && response2 !== 'error') {
                                    let currentcountry = null;
                                    Object.keys(response2).forEach(function (key) {
                                        if (response.address.country === response2[key].recurly_mapping) {
                                            currentcountry = response2[key]
                                        }
                                    });
                                    this.setState({currentBillingInfo: response, currentCountry: currentcountry})
                                }
                            })
                    } else {
                        this.setState({
                            currentBillingInfo: response,
                            currentCountry: this.props.history.location.state.currentCountry
                        })
                    }
                }
            });

            // Get the subscription plan
            getMerchantSubscriptionPlan(this.props.history.location.state.pipioJSON.subscription, response => {
                if (response && response !== 'error') {
                    this.setState({ plan : response})
                }
            })

        } else {
            this.props.history.push('/MerchantSignIn')
        }
    }

    _onIdle(e) {

        if (this.stopIdleTimer)
            this.idleTimer.pause()
        else {
                setMerchantAuthToken(getAuthToken())
                logout(response => {
                    storeAuthToken("")
                    return this.props.history.push('/MerchantSignIn');
                });

        }
    }

    render() {

        const { merchant_id, plan_code, currentBillingInfo, plan} = this.state


        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    debounce={250}
                    onIdle={this.onIdle}
                    timeout={1000 * this.seconds } />
                {
                    <div>
                        <Header title="Merchant account - Payment" history={this.props.history}
                                pipioJSON={this.state.pipioJSON}
                                isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null}/>
                        <section className="policies">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 mb-5">
                                        <div className="card card-signin card-body">
                                            <h3>Plan Summary:</h3>
                                            <br/>
                                            {plan ? (
                                                <Table hover>
                                                    <tbody>
                                                    <tr>
                                                        <td><strong>Type:</strong></td>
                                                        <td>{plan.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Price:</strong></td>
                                                        <td>{plan.price.price_unit + plan.price.value} / {plan.price.term_unit}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <strong>Description:</strong>
                                                            <br/><br/>
                                                            {plan.description}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-8 my-auto">
                                        <h3>Billing information</h3>
                                        <div className="DemoSection">
                                            <CardElement fontSize={18} account_code={merchant_id} price={ plan ? plan.price.price_unit + plan.price.value : null}
                                                         currentBillingInfo={currentBillingInfo}
                                                         isLoggedIn={this.props.history.location.state ? this.props.history.location.state.isLoggedIn : null}
                                                         plan_code={plan_code} history={this.props.history}
                                                         pipioJSON={this.props.history.location.state ? this.props.history.location.state.pipioJSON : null}
                                                         isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null}
                                                         currentCountry={this.state.currentCountry}
                                                         noBillingInfo={ this.state.nobillinginfo }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer/>
                    </div>
                }
            </div>
        );
    }
}


export default MerchantPayment;
