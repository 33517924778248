import React from 'react';

import { createMerchant, getSubcategories, setMerchantAuthToken, updateUser } from './Utilities/DataManager';
import { getAuthToken, getUserId } from './Utilities/StorageManager';

import Header from "./_library/Header";
import Footer from "./_library/Footer";
import Divider from './_library/Divider';
import ModalCustom from "./_library/ModalCustom";

import {Button, DropdownButton, InputGroup,  Dropdown, Table} from "react-bootstrap";

class MerchantCategories extends React.Component {

    constructor(props) {
        super(props);

        this.categories = [
            { name: 'Activities / Events', image: require('./assets/img/Categories/EventsPref.png') },
            { name: 'Food / Drink', image: require('./assets/img/Categories/DiningPref.png') },
            { name: 'Retail', image: require('./assets/img/Categories/RetailPref.png') },
            { name: 'Services', image: require('./assets/img/Categories/ServicesPref.png') },
        ];

        this.state = {
            ...this.getSubcategories(),
            allSubCategories: [],
            business_name: "mybusiness",
            category1: 'Sub-category 1',
            category2: 'Sub-category 2',
            category3: 'Sub-category 3',
            showCategoryNeededError: false,
            showMerchantCreationError:false,
            showRetrieveSubcategoriesError: false,
            pipioJSON: undefined
        };


    }

    isSubmiting = false;

    componentDidMount() {

        if (this.props.history.location.state) {
            this.setState({pipioJSON: this.props.history.location.state.pipioJSON})

            this.retrieveSubCategories();
        } else {
            this.props.history.push('/MerchantSignIn')
        }
    }

    getSubcategories() {

        return {
            category1: 'Sub-category 1',
            category2: 'Sub-category 2',
            category3: 'Sub-category 3',
        };
    }

    getSubcategory(index) {
        return this.state.allSubCategories[index];
    }

    retrieveSubCategories() {
        let processed = 0;
        const allSubCategories = [];
        for (let i = 0; i < 4; i++) {
            getSubcategories(i + 1, res => {

                if (res === 'error' && processed === 0) {
                    this.displayFetchErrorAlert();
                    return;
                }
                const subCategories = [];
                Object.keys(res).map((subCategory) => {
                    subCategories.push({ key: res[subCategory].name });
                });
                allSubCategories[i] = subCategories;
                processed++;
                if (processed === this.categories.length) {
                    this.setState({ allSubCategories });
                }
            });
        }
    }

    submitJson() {

        if (this.isSubmiting) {
            return;
        }
        this.isSubmiting = true;

        if (!this.state.category) {
            this.setState({ showCategoryNeededError : true });
            this.isSubmiting = false;
            return;
        }

        const { pipioJSON, category1, category2, category3 } = this.state;
        pipioJSON.category = this.state.category

        pipioJSON.sub_categories = [];
        if (category1 !== 'Sub-category 1') {
            pipioJSON.sub_categories.push(category1);
        }
        if (category2 !== 'Sub-category 2') {
            pipioJSON.sub_categories.push(category2);
        }
        if (category3 !== 'Sub-category 3') {
            pipioJSON.sub_categories.push(category3);
        }

        setMerchantAuthToken(getAuthToken())
        createMerchant(pipioJSON, merchantRes => {
            if (merchantRes === 'error' || !merchantRes) {
                this.setState( { showMerchantCreationError : true });
                this.isSubmiting = false;
            } else {

                const updateuserJSON = {
                    merchant_id: merchantRes.merchant_id,
                };
                pipioJSON.merchant_id = merchantRes.merchant_id;
                getUserId(id => {

                    if (id === 'error') {
                        alert('User ID is not saved');
                        this.isSubmiting = false;
                        return;
                    } else {

                        updateUser(updateuserJSON, id, getAuthToken(), userRes => {
                            if (userRes === 'error') {
                                alert('User could not be updated');
                                this.isSubmiting = false;
                                return;
                            } else {
                                this.props.history.push( '/MerchantSubscription' , { pipioJSON : pipioJSON, merchant_id : pipioJSON.merchant_id,  isRegistration: this.props.history.location.state.isRegistration, currentCountry: this.props.history.location.state.currentCountry });
                            }
                        });
                    }
                });
            }
        });
    }

    displayFetchErrorAlert() {
        this.setState( { showRetrieveSubcategoriesError : true } )
    }

    changeValueCategory1(text) {
        this.setState({category1: text})
    }

    changeValueCategory2(text) {
        this.setState({category2: text})
    }

    changeValueCategory3(text) {
        this.setState({category3: text})
    }

    render() {

        const { showCategoryNeededError, showMerchantCreationError, showRetrieveSubcategoriesError  } = this.state;
        const category1Categories = [];
        const category2Categories = [];
        const category3Categories = [];

        if (this.state.allSubCategories[this.state.category - 1]) {
            category1Categories.push(...this.state.allSubCategories[this.state.category - 1].filter(({ key }) => key !== this.state.category2 && key !== this.state.category3));
            category2Categories.push(...this.state.allSubCategories[this.state.category - 1].filter(({ key }) => key !== this.state.category1 && key !== this.state.category3));
            category3Categories.push(...this.state.allSubCategories[this.state.category - 1].filter(({ key }) => key !== this.state.category1 && key !== this.state.category2));
        }

        return (
            <div>
                <Header title="Merchant setup - Category" isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null}/>
                <section className="policies">
                    <div className="container">

                       <ModalCustom show={showCategoryNeededError} title="Error!"
                            body={ "You must select at lease one category before proceeding." }
                            onHide={ () => {this.setState( {showCategoryNeededError: false} ) }}
                            button1Caption={"Ok"}
                            button1Action={ () => {this.setState( {showCategoryNeededError: false} ) }} />

                       <ModalCustom show={showMerchantCreationError} title="Error!"
                            body={ "An error has occurred during the creation of the merchant account. We apologize for this inconvenience. Please try again later." }
                            onHide={ () => {this.setState( {showMerchantCreationError: false} ) }}
                            button1Caption={"Ok"}
                            button1Action={ () => {this.setState( {showMerchantCreationError: false} ) }} />

                        <ModalCustom show={showRetrieveSubcategoriesError} title="Error!"
                            body={ "Subcategories could not be retrieved." }
                            onHide={ () => {this.setState( {showRetrieveSubcategoriesError: false} ) }}
                            button1Caption={"Ok"}
                            button1Action={ () => {this.setState( {showRetrieveSubcategoriesError: false} ) }} />

                        <div className="row justify-content-md-center">
                            <div className="col-lg-4 my-auto">
                                <InputGroup>
                                    <Table hover>
                                        <tbody>
                                        {this.categories.map((category, index) => (
                                            <tr key={category.name} onClick={() => {
                                                if (this.state.category !== index + 1) {
                                                    const subCategories = this.getSubcategory(index);
                                                    this.setState({
                                                        category: index + 1,
                                                        subCategories,
                                                        category1: 'Sub-category 1',
                                                        category2: 'Sub-category 2',
                                                        category3: 'Sub-category 3',
                                                    });
                                                }}}>
                                                <td><img alt={category.name} src={category.image} width="35" height="35"/></td>
                                                <td>{category.name}</td>
                                                { (index + 1) === this.state.category ? (
                                                    <td><input className="form-check-input" type="radio" checked/></td>
                                                ) : (
                                                    <td><input className="form-check-input" type="radio"/></td>
                                                ) }
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </InputGroup>
                            </div>
                            <div className="col-lg-4 my-auto text-center">
                                <div>
                                    <DropdownButton id="dropdown-basic-button"
                                        title={this.state.category1} >
                                        {
                                            Object.keys(category1Categories).map((e) => {
                                                return <Dropdown.Item key={category1Categories[e].key} as="button"><div onClick={(e) => this.changeValueCategory1(e.target.textContent)}>{category1Categories[e].key}</div></Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                </div>
                                <Divider/>
                                <div>
                                    <DropdownButton id="dropdown-basic-button"
                                        title={this.state.category2} >
                                        {
                                            Object.keys(category2Categories).map((e) => {
                                                return <Dropdown.Item key={category2Categories[e].key} as="button"><div onClick={(e) => this.changeValueCategory2(e.target.textContent)}>{category2Categories[e].key}</div></Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                </div>
                                <Divider/>
                                <div>
                                    <DropdownButton id="dropdown-basic-button"
                                        title={this.state.category3} >
                                        {
                                            Object.keys(category3Categories).map((e) => {
                                                return <Dropdown.Item key={category2Categories[e].key} as="button"><div onClick={(e) => this.changeValueCategory3(e.target.textContent)}>{category3Categories[e].key}</div></Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                </div>
                                <br />
                            </div>
                            <div className="col-lg-8 my-auto text-center">
                                <p>
                                    If you don't see an appropriate sub-category, you can request one on the Category screen in the pipio app once setup is complete.
                                </p>
                                <Button  className="btn btn-primary mb-2 px-5" type="confirm" onClick={() => { this.submitJson() }}>
                                    Confirm merchant creation
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default MerchantCategories;
