import React from 'react';
import {Button, Form, Col } from 'react-bootstrap';

import { postResetPasswordEmail } from './Utilities/DataManager';

import Header from "./_library/Header";
import Footer from "./_library/Footer";

class MerchantResetPassword extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showError: false,
            rememberEmail: false,

            formValues: {
                email: "",
            },
            formErrors: {
                email: "",
            },
            formValidity: {
                email: false,
            },
            isSubmitting: false,

            email: "",
        };

    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isEmail = name === "email";

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : "ERROR";


        if (validity[name]) {
            if (isEmail) {
                const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                validity[name] = emailRegex.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    handleSubmit = event => {

        event.preventDefault();
        this.setState({isSubmitting: true});
        const {formValues, formValidity} = this.state;

        if (Object.values(formValidity).every(Boolean)) {

            this.ResetPassword();
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                this.handleValidation(target);
            }
            this.setState({isSubmitting: false});
        }
    }

    handleChange = ({ target }) => {

        const { formValues } = this.state;
        formValues[target.name] = target.value;

        if (target.name === 'email')
            this.setState({ email: target.value })

        this.setState({ formValues });
        this.handleValidation(target);
    };

    ResetPassword() {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        const email = this.state.formValues['email'];
        postResetPasswordEmail(email, payload => {
            if (payload === 'error') {
                return;
            }
            this.isSubmitting = false;
            if (payload.status === 'success')
                this.props.history.push('/MerchantSignIn')
        });
    }

    render() {

        const { formErrors } = this.state;
        return (
            <div>

                <Header title="Merchant account - Reset your password" isRegistration={true}/>

                <section class="pricing py-6">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-9 mx-auto">
                                <div class="card card-signin flex-row">
                                    <div class="card-img-left d-none d-md-flex">
                                    </div>
                                    <div class="card-body text-center">
                                        <h3 className="login-heading mb-4">Reset your password</h3>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Group as={Col} controlId="formEmail">
                                                <Form.Control type="email"
                                                              name="email"
                                                              autoCapitalize={'none'}
                                                              autoCompleteType={'email'}
                                                              placeholder="Email address*"
                                                              onChange={this.handleChange}
                                                              isInvalid={!!formErrors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <div className="text-center">
                                                <Button className="btn btn-primary mb-2 px-5" variant="primary" type="submit">
                                                    Reset your password
                                                </Button>
                                            </div>
                                        </Form>
                                        If your email address matches our records, an email with a reset link will be sent to you.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default MerchantResetPassword;
