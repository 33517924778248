import React, { Component } from "react";

import { Form, Button, Col } from "react-bootstrap";
import { Formik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {getSupportedCountries, getIPGeoLookup} from './Utilities/DataManager';

import Header from "./_library/Header";
import Footer from "./_library/Footer";
import Alert2Buttons from './_library/Alert2Buttons';

class MerchantSetupProfile extends Component {

    constructor(props) {
        super(props);

        this.facebookURL = 'https://www.facebook.com/';
        this.twitterURL = 'https://www.twitter.com/';
        this.instagramURL = 'https://www.instagram.com/';

        this.businessNameMax = 100;
        this.businessaddress_line_1Max = 100;
        this.businessAddressline2Max = 100;
        this.cityMax = 60;
        this.zipMax = 9;
        this.websiteMax = 100;
        this.facebookMax = 100;
        this.twitterMax = 100;
        this.instagramMax = 100;
        this.business_descriptionMax = 1000;
        this.phoneNumberMax = 20;

        this.state = {

            typeAlert: "",
            titleAlert: "",
            msgAlert: "",
            button1onClick: "",
            button2onClick: "",
            button1Caption: "",
            button2Caption: "",
            showAlert: false,

            business_name: "",
            address_line_1: "",
            address_line_2: "",
            city: "",
            state:  "",
            zipcode:  "",
            phone:  "",
            website:  "",
            facebook:  "",
            twitter: "",
            instagram: "",
            business_description:  "",
            countries: [],
            supportedcountries: null,
            currentcountry: null,
            ipgeocountry: null,

            formValues: {
                business_name: "",
                address_line_1: "",
                address_line_2: "",
                city: "",
                state: "",
                country: "United States",
                zipcode: "",
                phone: "",
                website: "",
                facebook: "",
                twitter: "",
                instagram: "",
                business_description: ""
            },
            formErrors: {
                business_name: "",
                address_line_1: "",
                address_line_2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                phone: "",
                website: "",
                facebook: "",
                twitter: "",
                instagram: "",
                business_description: "",
            },
            formValidity: {
                business_name: false,
                address_line_1: false,
                address_line_2: false,
                city: false,
                state: false,
                country: false,
                zipcode: false,
                phone: false,
                agreed: true,
                business_description: true,
                email: true,
                facebook: true,
                twitter: true,
                instagram: true,

            },
            isSubmitting: false,
        };

        this.states = [];
        this.countries = []

    }

    isSubmitting = false;

    submit() {

        let pipioJSON = JSON.parse(JSON.stringify(this.state.formValues))

        // Redirect to Categories
        this.props.history.push( '/MerchantCategories' , { pipioJSON, isRegistration: this.props.history.location.state.isRegistration, currentCountry : this.state.currentcountry });

    }

    showAlert() {
        this.setState( { showAlert : false} )
    }

    handleChange = ({ target }) => {

        if (target.name === 'country') {

          let pipioJSON = this.props.history.location.state.pipioJSON;

            this.state.currentcountry = this.state.supportedcountries[target.value];

            let formValidity = {
                    business_name: false,
                    address_line_1: !this.state.currentcountry.address_line_1.required,
                    address_line_2: !this.state.currentcountry.address_line_2.required,
                    city: !this.state.currentcountry.city.required,
                    state:  !this.state.currentcountry.state.required,
                    country: false,
                    zipcode: false,
                    phone: false,
                    agreed: true,
                    business_description: true,
                    email: true,
                    facebook: true,
                    twitter: true,
                    instagram: true,
            };

            let formValues = {
                    business_name: document.getElementById("business_name").value,
                    address_line_1: this.state.currentcountry.address_line_1.name && (document.getElementById("address_line_1") !== null) ? document.getElementById("address_line_1").value : '',
                    address_line_2: this.state.currentcountry.address_line_2.name && (document.getElementById("address_line_2") !== null) ? document.getElementById("address_line_2").value : '',
                    city: this.state.currentcountry.city.name && (document.getElementById("city") !== null) ? document.getElementById("city").value : '',
                    state: this.state.currentcountry.state.name && (document.getElementById("state") !== null) ? document.getElementById("state").value : '',
                    agreed: true,
                    country: target.value,
                    zipcode: this.state.currentcountry.zipcode.name && (document.getElementById("zipcode") !== null) ? document.getElementById("zipcode").value : '',
                    phone: '',
                    website: (document.getElementById("website") !== null) ? document.getElementById("website").value : '',
                    facebook: this.state.facebook,
                    twitter: this.state.twitter,
                    instagram: this.state.instagram,
                    business_description: this.state.business_description,
                    email: pipioJSON['email'],
            };

            let formErrors = {
                    business_name: "",
                    address_line_1: "",
                    address_line_2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: "",
                    phone: "",
                    website: "",
                    facebook: "",
                    twitter: "",
                    instagram: "",
                    business_description: "",
            };

            this.setState({currentcountry: this.state.supportedcountries[target.value], formValues})
            this.state.formValidity = formValidity;
            this.state.formValues = formValues;
            this.state.formErrors = formErrors;
        }

        const { formValues } = this.state;
        formValues[target.name] = target.value;
        this.setState({ formValues });
        this.handleValidation(target);
    };

    handleValidation = target => {
        const {name, value} = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isWebsite = name === "website";
        const isPhone = name === "phone";

        validity["email"] = true;
        validity["agreed"] = true;
        validity["business_description"] = true;
        validity["facebook"] = true;
        validity["twitter"] = true;
        validity["instagram"] = true;

        if (!validity[name]) {
            validity[name] = value.length > 0;
            fieldValidationErrors[name] = validity[name]
                ? ""
                : "ERROR";
        } else {
            validity[name] = true
            fieldValidationErrors[name] = ""
        }

        if (isWebsite) {
            validity[name] = this.isValidURL(value);
            fieldValidationErrors[name] = validity[name]
                ? ""
                : "ERROR";
        }

        if (isPhone) {

            if (this.state.formValues["country"] === 'United States') {
                validity[name] = this.isValidPhone(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            } else {
                validity[name] = value.length > 0;
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    handleSubmit = event => {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        event.preventDefault();
        this.setState({isSubmitting: true});
        const {formValues, formValidity} = this.state;

        if (Object.values(formValidity).every(Boolean)) {

            this.submit()
            this.isSubmitting = false;
        } else {


            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                this.handleValidation(target);

                if (Object.values(formValidity).every(Boolean)) {
                    this.submit()
                    this.isSubmitting = false;
                }

            }
            this.isSubmitting = false;
        }
    }

    isValidURL(str) {
        if (str) {
            let regex = /^([h|H][t|T][t|T][p|P][s|S]?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            if (!regex.test(str)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    isValidPhone(str) {
        let regex = /\d{3}[-\.\s]\d{3}[-\.\s]\d{4}$/;
        if (!regex.test(str)) {
            return false;
        } else {
            return true;
        }
    }

    getFormattedPhoneNum(text) {

        if (this.state.formValues["country"] === 'United States') {
            var cleaned = ('' + text).replace(/\D/g, '')
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

            this.state.formErrors['phone'] = "";

            if (match) {
                var intlCode = (match[1] ? '+1 ' : ''),
                    //number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
                    number = [intlCode, match[2], '-', match[3], '-', match[4]].join('');
                this.state.formValues['phone'] = number;
                this.setState({
                    phone: number, formErrors: this.state.formErrors
                });
                return;
            } else {
                this.state.formValues['phone'] = text;
                this.setState({
                    phone: this.state.formValues['phone'], formErrors: this.state.formErrors
                });
                return;
            }
        } else {

            this.state.formValues['phone'] = text;
            this.setState({
                phone: this.state.formValues['phone'], formErrors: this.state.formErrors
            });
            return;
        }

        /*
        this.state.formValues['phone'] = text;
        this.setState( { phone : this.state.formValues['phone'], formErrors :  this.state.formErrors})
        */
    }

    componentDidMount(){

        let countries = []
        getSupportedCountries(response => {
            if (response && response !== 'error') {
                Object.keys(response).forEach(function(key) {
                    countries.push(key);
                });
                // Default Country is UK if geo ip does not return anything I support.
                let country = 'United States'

                // Let's find the country I am in if possible.
                getIPGeoLookup( response2 => {

                    if (response2 && response2 !== 'error') {
                        if (countries.indexOf(response2.country) > -1) {
                            country = response2.country
                        }
                        this.setState( { ipgeocountry : response2.country, countries : countries, supportedcountries: response, currentcountry: response[country], states: response['United States'].state.options } )
                    } else {
                        this.setState( { countries : countries, supportedcountries: response, currentcountry: response['United States'], states: response['United States'].state.options } )
                    }

                    let formValues = {
                        business_name: "",
                        address_line_1: "",
                        address_line_2: "",
                        city: "",
                        state: "",
                        country: country,
                        zipcode: "",
                        phone: "",
                        website: "",
                        facebook: "",
                        twitter: "",
                        instagram: "",
                        business_description: ""
                    }
                    this.state.formValues = formValues;

                    let formValidity = {
                        business_name: false,
                        address_line_1: !response[country].address_line_1.required,
                        address_line_2: !response[country].address_line_2.required,
                        city: !response[country].city.required,
                        state:  !response[country].state.required,
                        country: false,
                        zipcode: !response[country].zipcode.required,
                        phone: false,
                        agreed: true,
                        business_description: true,
                        email: true,
                        facebook: true,
                        twitter: true,
                        instagram: true,

                    }
                    this.state.formValidity = formValidity;

                    if (this.props.history.location.state) {
                        // load data from previous form
                        let pipioJSON = this.props.history.location.state.pipioJSON;

                        this.setState({pipioJSON})

                        // get needed previous form values
                        this.state.formValues['agreed'] = pipioJSON['agreed']
                        this.state.formValues['email'] = pipioJSON['email']
                    } else {
                        this.props.history.push('/MerchantSignIn')
                    }
                })




            }
        })






    }

    render() {

        const { formValues, formErrors, showAlert, typeAlert, titleAlert, msgAlert, button2onClick, button1Caption, button2Caption } = this.state;

        const styles = {
          bird: {
            zIndex: 100,
          },
          faAsterisk: {
            color: '#fab040',
          }
        };

        return (
            <div>
                <Header title="Merchant setup - Profile" isRegistration={ this.props.history.location.state ? this.props.history.location.state.isRegistration : null }/>

                <section className="policies">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 my-auto">

                              <h3>Business Location</h3>
                              <Formik
                                  validationSchema={this.schema}
                                  onSubmit={console.log}
                                  initialValues={{

                                  }}
                              >
                              {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                              <Form onSubmit={this.handleSubmit}>
                                  <Form.Group controlId="formBusinessName">
                                      <Form.Control placeholder="Business name*"
                                                    maxLength={this.businessNameMax}
                                                    type="text"
                                                    name="business_name"
                                                    id="business_name"
                                                    onChange={this.handleChange}
                                                    isInvalid={!!formErrors.business_name}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          Business name is required and cannot be empty
                                      </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group controlId="formGridCountry">
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control as="select"
                                                    name="country"
                                                    onChange={this.handleChange}
                                                    isInvalid={!!formErrors.country}>
                                          {this.state.countries.map((e, key) => {
                                              return (e === this.state.ipgeocountry) ? (
                                                  <option key={key} value={e} selected>{e}</option>
                                              ) : (
                                                  <option key={key} value={e}>{e}</option>
                                              )

                                          })}
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                          Please select a country.
                                      </Form.Control.Feedback>
                                  </Form.Group>

                                  { this.state.currentcountry && this.state.currentcountry.address_line_1 && this.state.currentcountry.address_line_1.name ? (
                                      <Form.Group controlId="formGridAddress1">
                                          <Form.Label>{ this.state.currentcountry.address_line_1.name }</Form.Label>
                                          <Form.Control placeholder={ this.state.currentcountry && this.state.currentcountry.address_line_1.hint ? this.state.currentcountry.address_line_1.hint : this.state.currentcountry.address_line_1.name }
                                                        maxLength={this.businessaddress_line_1Max}
                                                        type="text"
                                                        name="address_line_1"
                                                        id="address_line_1"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.address_line_1}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                              Please provide an { this.state.currentcountry.address_line_1.name.toLowerCase() }.
                                          </Form.Control.Feedback>
                                      </Form.Group>
                                  ) : null }

                                  { this.state.currentcountry && this.state.currentcountry.address_line_2 && this.state.currentcountry.address_line_2.name ? (
                                      <Form.Group controlId="formGridAddress2">
                                          <Form.Label>{ this.state.currentcountry.address_line_2.name }</Form.Label>
                                          <Form.Control placeholder={ this.state.currentcountry && this.state.currentcountry.address_line_2.hint ? this.state.currentcountry.address_line_2.hint : this.state.currentcountry.address_line_2.name }
                                                        maxLength={this.businessAddressline2Max}
                                                        type="text"
                                                        name="address_line_2"
                                                        id="address_line_2"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.address_line_2}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                              Please provide a complement to your address.
                                          </Form.Control.Feedback>
                                      </Form.Group>
                                  ) : null }

                                  <Form.Row>
                                      { this.state.currentcountry && this.state.currentcountry.city && this.state.currentcountry.city.name ? (
                                          <Form.Group as={Col} md="6" controlId="formGridCity">
                                               <Form.Label>{ this.state.currentcountry.city.name }</Form.Label>
                                              <Form.Control type="text"
                                                        placeholder={ this.state.currentcountry && this.state.currentcountry.city.hint ? this.state.currentcountry.city.hint : this.state.currentcountry.city.name }
                                                        maxLength={this.cityMax}
                                                        name="city"
                                                        id="city"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.city}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                  Please provide a { this.state.currentcountry.city.name.toLowerCase() }.
                                              </Form.Control.Feedback>
                                          </Form.Group>
                                      ) : null }

                                      { this.state.currentcountry && this.state.currentcountry.state && this.state.currentcountry.state.name ? (
                                          <Form.Group as={Col} md="3" controlId="formGridState">
                                              <Form.Label>{ this.state.currentcountry.state.name }</Form.Label>
                                              <Form.Control as="select"
                                                            name="state"
                                                            id="state"
                                                            onChange={this.handleChange}
                                                            isInvalid={!!formErrors.state}>
                                                  <option>Choose...*</option>
                                                  {this.state.states.map((e, key) => {
                                                      return <option key={key} value={e}>{e}</option>;
                                                  })}
                                              </Form.Control>
                                              <Form.Control.Feedback type="invalid">
                                                  Please select a { this.state.currentcountry.state.name.toLowerCase() }.
                                              </Form.Control.Feedback>
                                          </Form.Group>
                                      ) : null }
                                      { this.state.currentcountry && this.state.currentcountry.zipcode && this.state.currentcountry.zipcode.name ? (
                                          <Form.Group as={Col} md="3" controlId="formGridZip">
                                              <Form.Label>{ this.state.currentcountry.zipcode.name }</Form.Label>
                                              <Form.Control name="zipcode"
                                                            id="zipcode"
                                                            placeholder={ this.state.currentcountry && this.state.currentcountry.zipcode.hint ? this.state.currentcountry.zipcode.hint : this.state.currentcountry.zipcode.name }
                                                            maxLength={this.zipMax}
                                                            type={"text"}
                                                            onChange={this.handleChange}
                                                            isInvalid={!!formErrors.zipcode}/>
                                              <Form.Control.Feedback type="invalid">
                                                  Please provide a { this.state.currentcountry.zipcode.name.toLowerCase() }.
                                              </Form.Control.Feedback>
                                          </Form.Group>
                                      ) : null }
                                  </Form.Row>

                                  <Form.Group controlId="formphone">
                                      <Form.Label>Business Phone</Form.Label>
                                      <Form.Control placeholder={ this.state.formValues["country"] === 'United States' ? "xxx-xxx-xxxx*" : "xxxxxxxxxxxxxxxx" }
                                                    type={"text"}
                                                    maxLength={this.state.formValues["country"] === 'United States' ? 12 : this.phoneNumberMax }
                                                    value={this.state.phone}
                                                    onChange={ (value) => { this.getFormattedPhoneNum(value.target.value) }}
                                                    name="phone"
                                                    id="phone"
                                                    isInvalid={!!formErrors.phone}/>
                                      <Form.Control.Feedback type="invalid">
                                          Please provide a valid phone number.
                                      </Form.Control.Feedback>
                                  </Form.Group>
                                  <h3>Business Description</h3>
                                  <Form.Group controlId="formBusinessDescription">
                                      <Form.Control as="textarea"
                                                    placeholder="Enter a description of your business"
                                                    name="business_description"
                                                    id="business_description"
                                                    value={this.state.business_description}
                                                    onChange={ (value) => {
                                                        if (value.target.value.length <= this.business_descriptionMax) {
                                                            formValues['business_description'] = value.target.value;
                                                            this.setState( { business_description: value.target.value })
                                                        }
                                                    }}/>
                                      <Form.Label>{ this.business_descriptionMax - this.state.business_description.length } / {this.business_descriptionMax} characters left</Form.Label>
                                  </Form.Group>


                                  <h3>Social Links</h3>
                                  <Form.Group controlId="formWebsite">
                                      <Form.Label>Website</Form.Label>
                                      <Form.Control placeholder="Website URL"
                                                    maxLength={this.websiteMax}
                                                    type={"text"}
                                                    name="website"
                                                    id="website"
                                                    onChange={this.handleChange}
                                                    isInvalid={!!formErrors.website} />
                                      <Form.Control.Feedback type="invalid">
                                          Please provide a valid URL.
                                      </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="formFacebook">
                                      <Form.Label>Facebook</Form.Label>
                                      <Form.Control placeholder="https://www.facebook.com/"
                                                    maxLength={this.facebookMax}
                                                    type={"text"}
                                                    value={this.state.facebook}
                                                    name="facebook"
                                                    id="facebook"
                                                    onChange={ (value) => {
                                                        let URL = this.facebookURL + value.target.value.substring(this.facebookURL.length, this.facebookMax);
                                                        formValues['facebook'] = URL;
                                                        this.setState({ facebook: URL, formValues   })}
                                                    }/>
                                  </Form.Group>
                                  <Form.Group controlId="formTwitter">
                                      <Form.Label>Twitter</Form.Label>
                                      <Form.Control placeholder="https://www.twitter.com/"
                                                    maxLength={this.twitterMax}
                                                    type={"text"}
                                                    value={this.state.twitter}
                                                    name="twitter"
                                                    id="twitter"
                                                    onChange={ (value) => {
                                                        let URL = this.twitterURL + value.target.value.substring(this.twitterURL.length, this.twitterMax);
                                                        formValues['twitter'] = URL;
                                                        this.setState({ twitter: URL, formValues   })}
                                                    }/>
                                  </Form.Group>
                                  <Form.Group controlId="formInstagram">
                                      <Form.Label>Instagram</Form.Label>
                                      <Form.Control placeholder="https://www.instagram.com/"
                                                    maxLength={this.instagramMax}
                                                    type={"text"}
                                                    value={this.state.instagram}
                                                    name="instagram"
                                                    id="instagram"
                                                    onChange={ (value) => {
                                                        let URL = this.instagramURL + value.target.value.substring(this.instagramURL.length, this.instagramMax);
                                                        formValues['instagram'] = URL;
                                                        this.setState({ instagram: URL, formValues   })}
                                                    }/>
                                  </Form.Group>

                                  { showAlert ? (
                                      <Alert2Buttons typeAlert={typeAlert} titleAlert={titleAlert}
                                                     msgAlert={msgAlert} button1onClick={() => this.showAlert()}
                                                     button2onClick={button2onClick} button1Caption={button1Caption}
                                                     button2Caption={button2Caption} />
                                  ) : null }

                                  <div className="text-center">
                                      <Button className="btn btn-primary mb-2 px-5" type="submit">
                                          Next - Category
                                      </Button>
                                  </div>
                              </Form>
                                  )}
                              </Formik>
                            </div>

                            <div className="col-lg-4 mb-5">

                                <div className="bubble thought text-center">
                                    <p>
                                        <strong>
                                            Check it out!
                                        </strong>
                                    </p>
                                    <div className="bird-right" style={styles.bird}></div>
                                </div>

                                <div className="card card-signin card-body">
                                    <p>
                                    You can now add additional information to your <strong>Merchant Profile</strong> in the app, including:
                                    </p>

                                    <ul className="fa-ul">
                                        <li><FontAwesomeIcon icon="asterisk" style={styles.faAsterisk} /> business hours</li>
                                        <li><FontAwesomeIcon icon="asterisk" style={styles.faAsterisk} /> special hours</li>
                                    </ul>

                                    <p>
                                    You may also indicate if you offer the following services:
                                    </p>

                                    <ul className="fa-ul">
                                        <li><FontAwesomeIcon icon="asterisk" style={styles.faAsterisk} /> curbside delivery</li>
                                        <li><FontAwesomeIcon icon="asterisk" style={styles.faAsterisk} /> no-contact delivery</li>
                                        <li><FontAwesomeIcon icon="asterisk" style={styles.faAsterisk} /> video conferencing</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default MerchantSetupProfile;
