import React from 'react';
import {Button, Form, Col } from 'react-bootstrap';

import { postMerchantLogin } from './Utilities/DataManager';
import {backupMerchantEmail, retrieveMerchantEmail, storeAuthToken} from './Utilities/StorageManager';
import { isValidPassword } from './Utilities/Utils';
import ModalCustom from "./_library/ModalCustom";

import Header from "./_library/Header";
import Footer from "./_library/Footer";

class MerchantSignIn extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showError: false,
            rememberEmail: false,

            formValues: {
                email: "",
                password: "",
            },
            formErrors: {
                email: "",
                password: "",
            },
            formValidity: {
                email: false,
                password: false,
            },
            isSubmitting: false,

            email: "",
            password: "",

        };

    }

    componentWillMount() {

        retrieveMerchantEmail(email => {
            this.state.formValues['email'] = email
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            this.state.formValidity['email'] = emailRegex.test(email)
            this.setState({ email });
            if (email !== '') {
                this.setState({ rememberEmail: true  });
            }
        });
    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isEmail = name === "email";
        const isPassword = name === "password";

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : "ERROR";


        if (validity[name]) {
            if (isEmail) {
                const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                validity[name] = emailRegex.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }

            if (isPassword) {
                validity[name] = isValidPassword(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    handleSubmit = event => {

        event.preventDefault();
        this.setState({isSubmitting: true});
        const {formValues, formValidity} = this.state;

        if (Object.values(formValidity).every(Boolean)) {

            this.signIn();
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                this.handleValidation(target);
            }
            this.setState({isSubmitting: false});
        }
    }

    handleChange = ({ target }) => {

        const { formValues } = this.state;
        formValues[target.name] = target.value;

        if (target.name === 'email')
            this.setState({ email: target.value })
        if (target.name === 'password')
            this.setState({ password: target.value })

        this.setState({ formValues });
        this.handleValidation(target);
    };

    signIn() {

        this.isSubmitting = true;

        const email = this.state.formValues['email'];
        const password = this.state.formValues['password'];

        if (this.state.rememberEmail === false) {
            backupMerchantEmail('')
            this.setState({ email: '' } )
        } else {
            backupMerchantEmail(email)
            this.setState( { email } )
        }

        postMerchantLogin(email, password, (res) => {
            if (!res || res === 'error') {
                this.setState({ password: '', showError: true})
                this.state.formValidity['email'] = false;
                this.state.formValidity['password'] = false;

                this.state.formValues['email'] = this.state.email;
                this.state.formValues['password'] = '';

                this.isSubmitting = false;
                return;
            }

            storeAuthToken(res.auth_token);
            if (!res.merchant_id) {
                // Likely an unfinished registration - taking it back into the registration.
                let pipioJSON = {
                    email: email,
                    agreed: true,
                    subscription: undefined,
                    category: 0,
                    sub_categories: [],
                    business_name: '',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    phone: '',
                    website: '',
                    facebook: '',
                    twitter: '',
                    instagram: '',
                    business_description: '',
                    longitude: 1.0,
                    latitude: 1.0,
                };
                this.props.history.push('/MerchantSetupProfile', {isRegistration: true, pipioJSON});
                this.isSubmitting = false;
            } else {
                this.props.history.push('/MerchantSubscription', {fromSignIn: true, merchant_id: res.merchant_id});
                this.isSubmitting = false;
            }
        });

    }

    render() {

        const { formErrors, showError } = this.state;
        return (
            <div>

                <Header title="Merchant account - login" isRegistration={true}/>

                <section className="pricing py-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-9 mx-auto">
                                <div className="card card-signin flex-row">
                                    <div className="card-img-left d-none d-md-flex">
                                    </div>
                                    <div className="card-body text-center">
                                        <h3 className="login-heading mb-4">Welcome back!</h3>
                                        <ModalCustom show={showError} title="Sign in failed!"
                                            body={ "Access denied. Please check your email address and password and try again" }
                                            onHide={ () => {this.setState( {showError: false} ) }}
                                            button1Caption={"Ok"}
                                            button1Action={ () => {this.setState( {showError: false} ) }} />
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Group as={Col} controlId="formEmail">
                                                <Form.Control type="email"
                                                    name="email"
                                                    autoCapitalize={'none'}
                                                    autoCompleteType={'email'}
                                                    placeholder="Email address"
                                                    onChange={this.handleChange}
                                                    value={this.state.email}
                                                    isInvalid={!!formErrors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formPassword">
                                                <Form.Control type="password"
                                                    name="password"
                                                    placeholder="Password*"
                                                    onChange={this.handleChange}
                                                    value={this.state.password}
                                                    isInvalid={!!formErrors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a password or make sure it is as least 8 characters long.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="custom-control custom-checkbox mb-3 text-left" controlId="formRememberPassword">
                                                <Form.Check className="custom-control-input" type="checkbox" label="Remember email address" checked={this.state.rememberEmail}
                                                    onChange={() => this.setState({ rememberEmail: !this.state.rememberEmail })}/>
                                            </Form.Group>
                                            <div className="text-center">
                                                <Button className="btn btn-primary mb-2 px-5" variant="primary" type="submit">
                                                    Sign in
                                                </Button>
                                            </div>
                                        </Form>
                                        <a href="/MerchantResetPassword">Forgot your password</a><br/>
                                        Don't have an account? <a href="/MerchantSignUp">Register here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              <Footer />
            </div>
        );
    }
}

export default MerchantSignIn;
