import React from 'react';
import { getUserId } from './Utilities/StorageManager';
import { getUser, sendVerificationEmail } from './Utilities/DataManager';

import { Button, Label, Group} from 'react-bootstrap';
import ModalCustom from "./_library/ModalCustom";
import Header from "./_library/Header";
import Footer from "./_library/Footer";

class MerchantConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            showResendFailure: false,
            showResendSuccess: false,
            showEmailNotVerified: false,
            showError: false,
            msg: "",
            pipioJSON: undefined
        }


    }

    isSubmitting = false;

    componentDidMount(){

        if (this.props.history.location.state) {
            this.setState({pipioJSON: this.props.history.location.state.pipioJSON})
        } else {
            this.props.history.push('/MerchantSignIn')
        }
    }

    sendVerificationEmail() {

        this.setState( { showResendFailure : false })
        this.setState( { showResendSuccess : false })

        sendVerificationEmail(this.state.pipioJSON.email, (response) => {
            if (response === 'error') {
                this.setState( { showResendFailure : true })
            } else {
                this.setState( { showResendSuccess : true })
            }
        })
    }


    alertResend() {
        this.setState( { showEmailNotVerified : true } )
    }

    confirm() {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        getUserId(id => {

            if (id === 'error') {
                this.setState({ msg: 'Error retrieving user ID', showError: true } );
                this.isSubmitting = false;
                return;
            }
            getUser(id, res => {
                if (res.confirmed) {
                    this.isSubmitting = false;
                    this.props.history.push( '/MerchantSetupProfile' , { pipioJSON : this.state.pipioJSON, isRegistration: true });
                } else {
                    this.alertResend();
                    this.isSubmitting = false;
                }
            });
        });
    }


    render() {

        const { showError, showResendFailure, showResendSuccess, showEmailNotVerified, pipioJSON } = this.state;

        return (

            <div>
                <Header title="Merchant account - Email verification" isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null }/>
                <section className="pricing">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body mx-auto">
                                    <div className="text-center">
                                        { pipioJSON ? (
                                            <span>
                                                <p>
                                                    Please check your mailbox {pipioJSON.email} for an email from {process.env.REACT_APP_VERIFY_EMAIL}.
                                                </p>
                                                <p>
                                                    Click the verification link in the email to continue setting up your merchant account.
                                                </p>
                                                <p>
                                                    This link will be valid for {process.env.REACT_APP_VERIFY_TIME} hour(s).
                                                </p>
                                                <p>
                                                    <a href="#" onClick={()=>this.sendVerificationEmail()}>Resend verification email</a>
                                                    <ModalCustom show={showError} title="You got an error!"
                                                        body={ this.state.msg }
                                                        onHide={ () => {this.setState( {showError: false} ) }}
                                                        button1Caption={"Ok"}
                                                        button1Action={ () => {this.setState( {showError: false} ) }} />
                                                    <ModalCustom show={showResendFailure} title="You got an error!"
                                                        body={
                                                            <p>
                                                                Please try again later
                                                            </p>
                                                        }
                                                        onHide={ () => {this.setState( {showResendFailure: false} ) }}
                                                        button1Caption={"Ok"}
                                                        button1Action={ () => {this.setState( {showResendFailure: false} ) }} />
                                                    <ModalCustom show={showResendSuccess} title="Email successfully resent!"
                                                        body={
                                                            <p>
                                                                Please check your mailbox {pipioJSON.email}.
                                                            </p>
                                                        }
                                                        onHide={ () => {this.setState( {showResendSuccess: false} ) }}
                                                        button1Caption={"Ok"}
                                                        button1Action={ () => {this.setState( {showResendSuccess: false} ) }} />
                                                    <ModalCustom show={showEmailNotVerified} title="Email not verified!"
                                                        body={
                                                            <p>
                                                                Your email address has not been verified.
                                                                <br/><br />
                                                                Would you like to resend the verification email to the following address?
                                                                <br /><br />
                                                                {pipioJSON.email} 
                                                            </p>
                                                        }
                                                        onHide={ () => this.setState( { showEmailNotVerified : false} )}
                                                        button1Caption={"Yes"}
                                                        button1Action={() => { this.setState( { showEmailNotVerified : false}, () =>  this.sendVerificationEmail() ) }}
                                                        button2Caption={"No"}
                                                        button2Action={() => this.setState( { showEmailNotVerified : false} )} />
                                                </p>
                                            </span>
                                        ) : null}
                                        <br />
                                        <Button onClick={() => this.confirm()}  className="btn btn-primary mb-2 px-5" type="submit">
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}


export default MerchantConfirmation;
