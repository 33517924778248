const merchantAuth = { token: null };
const AUTH_TOKEN = 'auth_token';

export function getSubcategories(category, callback, page = 1, subcategories = []) {
    //Makes a HTTP get request to fetch JSON data from the API endpoint

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/preferences/subcategories/${category}?page=${page}&per_page=50`, {
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(res => {
            if (res.ok) { //Makes sure the request returned no HTTP error codes
                return res.json();
            }
            callback('error');
        })
        .then(resJSON => {
            subcategories.push(...(resJSON.items));
            if (resJSON.page < resJSON.pages) {
                getSubcategories(category, callback, page + 1, subcategories);
            } else {
                callback(subcategories);
            }
        })
        .catch(error => callback('error'));
}

export function getMerchantById(id, callback) {
    //Makes a HTTP get request to fetch JSON data from the API endpoint
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/merchant/` + id, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(res => {
            if (res.ok) { //Makes sure the request returned no HTTP error codes
                return res.json();
            }
            callback('error');
        })
        .then(resJSON => {
            callback(resJSON);
        })
        .catch(error =>
        {
            console.log(error)
            callback('retry')
        });
}

export function getSubscriptionPlans(callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/plan`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        });
}

export function getMerchantSubscriptionPlan(id, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/plan/` + id, {
        headers: {
            'Authorization': `Bearer ${merchantAuth.token}`,
            'x-api-key': process.env.REACT_APP_API_KEY
        },
    })
        .then(res => {
            if (res.ok) { //Makes sure the request returned no HTTP error codes
                return res.json();
            }
            callback('error');
        })
        .then(resJSON => {
            callback(resJSON);
        })
        .catch(error => callback('error'));
}

export function getEventTypes(callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/event_types`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        });
}

export function getSalesTypes(callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/v2/subscription/sale_types`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        });
}

export function registerUser(userJSON, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(userJSON),
    })
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return response.json();
        } else if (response.status === 400) {
            response.json().then(function(object) {
                if (object.message === 'Password does not meet criteria.') {
                    callback('passwordcriteria');
                } else {
                    callback('exists');
                }
            })

            return;
        }
        callback('error');
        throw Error(`Request rejected with status ${response.status}`);
    })
    .then((data) => {
        callback(data);
    });
}

export function sendVerificationEmail(email, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/emailconfirmation/resend/` + email, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }
            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        });
}

export function updateUser(updateuserJSON, userid, auth_token, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/user/` + userid , {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + auth_token,
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(updateuserJSON),
    })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }
            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        });
}

export function createMerchant(flitterJSON, callback) {
    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/merchant`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(flitterJSON),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        }
        callback('error');
    })
    .then(resJSON => {
        callback(resJSON);
    });
}

export function createMerchantBillingInfo(merchant_id, token, plan_code, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
        token: token.id,
        plan_code: plan_code,
    }
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/payment/billinginfo`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        }
        callback('error');
        return;
    }).then(resJSON => {
            if (resJSON) {
                callback(resJSON);
            }
    });
}

export function updateMerchantBillingInfo(merchant_id, token, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
        token: token.id
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/payment/billinginfo`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 204) {
            callback('success')
            return;
        }
        callback('error');
        return;
    }).then(resJSON => {
        if (resJSON) {
            callback(resJSON);
        }
    });
}

export function retrieveMerchantBillingInfo(merchant_id, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
    }
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/payment/billinginfo/details`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        }
        callback('error');
        return;
    }).then(resJSON => {
        if (resJSON) {
            callback(resJSON);
        }
    });
}

export function changeMerchantSubscription(merchant_id, plan_code, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
        plan_code: plan_code,
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/change`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        }
        callback('error');
        return;
    }).then(resJSON => {
            if (resJSON) {
                callback(resJSON);
            }
    });
}

export function cancelMerchantSubscription(merchant_id, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/cancel`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        }
        callback('error');
        return;
    }).then(resJSON => {
        if (resJSON) {
            callback(resJSON);
        }
    });
}

export function retrieveMerchantSubscription(merchant_id, fromSignIn, callback) {

    if (!fromSignIn) {
        callback('error');
        return;
    }

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/info`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        } else {
            if (response.status === 404) {
                callback('nosubscription');
                return;
            }
        }

        callback('error');
        return;
    }).then(resJSON => {
        if (resJSON) {
            callback(resJSON);
        }
    });
}



export function reactivateMerchantSubscription(merchant_id, callback) {

    if (!merchantAuth.token) {
        callback('error');
        return;
    }

    const payload = {
        merchant_id: merchant_id,
    }

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/subscription/reactivate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.json();
        } else {
            if (response.status === 404) {
                callback('nosubscription');
                return;
            }
        }

        callback('error');
        return;
    }).then(resJSON => {
        if (resJSON) {
            callback(resJSON);
        }
    });
}

export function logout(callback) {

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/logout`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ` + localStorage.getItem(AUTH_TOKEN),
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return 'success';
        }
        callback('error');
    })
    .then((data) => {
        callback(data);
    });

}

export function postMerchantLogin(email, password, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ email, password }),
    })
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return response.json();
        }
        return 'error';
    })
    .then((data) => {
        callback(data);
    })
    .catch(error => {
        callback('retry')
    });
}

export function setMerchantAuthToken(merchantAuthToken) {
    merchantAuth.token = merchantAuthToken;
}

export function getMerchantsByRadius(lat, long, radius, search, callback, page = 1, merchants = []) {
    if (!search) search = ''

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/merchant?page=${page}&per_page=100&latitude=${lat}&longitude=${long}&radius=${radius}&search=${search}`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return response.json();
        }

        callback('error');
        throw Error(`Request rejected with status ${response.status}`);
    })
    .then((data) => {
        merchants.push(...data.items);
        if (data.page < data.pages) {
            getMerchantsByRadius(lat, long, radius, search, callback, page + 1, merchants)
        } else {
            callback(merchants);
        }
    });
}

export function getDevicesLastActivity(callback, page = 1, devices = []) {

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/geofence/activity?page=${page}&per_page=50`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }

            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            devices.push(...data.items);
            if (data.page < data.pages) {
                if (page < 50)
                    getDevicesLastActivity(callback, page + 1, devices)
                else
                    callback(devices);
            } else {
                callback(devices);
            }
        });
}

export function getUser(userID, callback) {
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/user/${userID}`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${merchantAuth.token}`,
        },
    })
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return response.json();
        }

        callback('error');
        throw Error(`Request rejected with status ${response.status}`);
    })
    .then((data) => {
        callback(data);
    })
    .catch(error => {
        callback('retry')
    });
}

export function postResetPasswordEmail(recipient, callback) {
    if (!merchantAuth.token) {
        callback('error');
    }
    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/auth/reset/password/request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ recipient, template_id: 2 }),
    })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }
            callback('error');
        })
        .then((data) => {
            callback(data);
        });
}

export function getSupportedCountries(callback) {

    fetch(process.env.REACT_APP_API_FLITTER_URL + `/merchants/supported/countries`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        },
    })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }

            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        })
        .catch(error => {
            callback('retry')
        });
}

export function getIPGeoLookup(callback) {
    fetch('https://extreme-ip-lookup.com/json/')
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                return response.json();
            }

            callback('error');
            throw Error(`Request rejected with status ${response.status}`);
        })
        .then((data) => {
            callback(data);
        })
        .catch(error => {
            callback('retry')
        });
}
