import React from "react";
import ReactDOM from "react-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faCheckSquare, faCoffee, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons'

import MerchantSignUp from "./MerchantSignUp";
import MerchantConfirmation from "./MerchantConfirmation";
import MerchantSetupProfile from "./MerchantSetupProfile";
import MerchantCategories from "./MerchantCategories";
import MerchantSubscription from "./MerchantSubscription";
import MerchantPayment from "./MerchantPayment";
import MerchantCreationSuccess from "./MerchantCreationSuccess";
import MerchantSignIn from "./MerchantSignIn";
import MerchantResetPassword from "./MerchantResetPassword";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import "./css/new-age.min.css";

library.add(fab, faCheckSquare, faCoffee, faCheck, faAsterisk, faTimes)

function App() {
  return (
      <Router>

          <Switch>
              <Route exact path='/' component={MerchantSignUp} />
              <Route path='/MerchantSignUp' component={MerchantSignUp} />
              <Route path='/MerchantConfirmation' component={MerchantConfirmation} />
              <Route path='/MerchantSetupProfile' component={MerchantSetupProfile} />
              <Route path='/MerchantCategories' component={MerchantCategories} />
              <Route path='/MerchantSubscription' component={MerchantSubscription} />
              <Route path='/MerchantPayment' component={MerchantPayment} />
              <Route path='/MerchantAccountBilling' component={MerchantPayment} />
              <Route path='/MerchantCreationSuccess' component={MerchantCreationSuccess} />
              <Route path='/MerchantSignIn' component={MerchantSignIn} />
              <Route path='/MerchantResetPassword' component={MerchantResetPassword} />
          </Switch>
      </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

