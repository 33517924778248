import React from 'react';

import Header from "./_library/Header";
import Footer from "./_library/Footer";

class MerchantCreationSuccess extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        // If accessed directly
        if (!this.props.history.location.state) {
            this.props.history.push('/MerchantSignIn')
        }
    }

    render() {

        return (
            <div>
                { this.props.history.location.state && this.props.history.location.state.isRegistration ? (
                        <Header title="Merchant account created successfully" history={this.props.history}
                            isRegistration={this.props.history.location.state.isRegistration}/>
                ) : (
                    <Header title="Merchant billing information updated successfully" history={this.props.history}
                            isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null }
                            pipioJSON={this.props.history.location.state ? this.props.history.location.state.pipioJSON : null } />
                )}
                <section className="pricing">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body mx-auto">
                                {   (this.props.history.location.state && this.props.history.location.state.isRegistration && this.props.history.location.state.isFreePlan) ? (
                                        <div className="text-center">
                                            Thanks for subscribing!
                                            <br/><br/>
                                            Your merchant account has been created but you cannot use the app just yet. <br/><br/>Because you are using a <b>FREE plan</b>, we need to make sure this is a real merchant account. <br/><br/>Review and <b>activation may take up to 72 hours</b>. We apologize for the inconvenience.
                                            <br/><br/>
                                            For now, please <a href={process.env.REACT_APP_DOWNLOAD_URL}>Download pipio</a>.
                                        </div>
                                    ) : null
                                }

                                { (this.props.history.location.state && this.props.history.location.state.isRegistration  && !this.props.history.location.state.isFreePlan) ? (
                                            <div className="text-center">
                                                Your merchant account has been created and is ready to use in the app.
                                                <br/><br/>
                                                <a href={process.env.REACT_APP_DOWNLOAD_URL}>Download pipio</a> and sign in with your credentials to get started!
                                            </div>
                                    ) : null
                                }

                                { (this.props.history.location.state && !this.props.history.location.state.isRegistration) ? (
                                            <div className="text-center">
                                                Thanks for updating your billing information!
                                            </div>
                                        ) : null
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}


export default MerchantCreationSuccess;
