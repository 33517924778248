import React, { Component } from 'react';
import {getAuthToken, storeAuthToken} from "../Utilities/StorageManager";
import {logout, setMerchantAuthToken} from '../Utilities/DataManager';


export default class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pipioJSON: undefined,
        }
    }

    logout() {

        setMerchantAuthToken(getAuthToken())
        logout(response => {
            storeAuthToken("")
            return this.props.history.replace('/MerchantSignIn');
        });
    }

    goToBilling() {
        return this.props.history.replace('/MerchantAccountBilling', { pipioJSON : this.state.pipioJSON, isLoggedIn: true });
    }

    goToSubscriptions() {
        return this.props.history.replace('/MerchantSubscription', { fromSignIn: true, pipioJSON : this.props.pipioJSON, merchant_id: this.props.pipioJSON.merchant_id, isLoggedIn: true });
    }

    componentDidMount() {
        this.history = this.props.history;
        this.setState( { pipioJSON : this.props.pipioJSON} )

    }

    componentDidUpdate(prevProps) {
        if (this.props.pipioJSON !== prevProps.pipioJSON) {
            this.setState( { pipioJSON : this.props.pipioJSON} )
        }
    }

    render() {
        const { title, isRegistration, pipioJSON } = this.props;

        return (
            <span>

                <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
                    <div className="container">
                        <div className="col text-left">
                            <a href="https://www.getpipio.com/"><img src="https://www.getpipio.com/img/PipioLogo_Horiz_Black.png" /></a>
                        </div>
                        <div className="col text-right">
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                                Menu
                                <i className="fas fa-bars"></i>
                            </button>

                            <span className="navigation_items">
                                <div className="collapse navbar-collapse" id="navbarResponsive">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            {!isRegistration && pipioJSON? (
                                                <a className="nav-link js-scroll-trigger" onClick={e => this.goToSubscriptions(e)}>Manage Subscriptions</a>
                                            ) : null }
                                        </li>
                                        
                                        {/* <li className="nav-item">
                                            {!isRegistration && pipioJSON? (
                                                <a className="nav-link js-scroll-trigger" onClick={e => this.goToBilling(e)}>Manage Billing Info</a>

                                            ) : null }
                                        </li> */}
                                        <li className="nav-item">
                                            { !isRegistration ? (
                                                    <a className="nav-link js-scroll-trigger" onClick={e=>this.logout(e)}>Sign out</a>
                                            ) : <a href="/MerchantSignIn" className="nav-link js-scroll-trigger">Sign in</a> }
                                        </li>
                                    </ul>
                                </div>
                            </span>
                        </div>
                    </div>
                </nav>


                <header className="sechead">
                    <div className="container h-100">
                        <div className="section-heading">
                            <h3 className="text-center">{title}</h3>
                        </div>
                    </div>
                </header>
            </span>
        );
    }
}
