import React, { useState } from 'react';

import {
    CardNumberElement,
    CardMonthElement,
    CardYearElement,
    CardCvvElement,
    Elements,
    RecurlyProvider,
    useRecurly
} from '@recurly/react-recurly';

import {Form, Col, Button} from 'react-bootstrap';

import { createMerchantBillingInfo, setMerchantAuthToken, updateMerchantBillingInfo } from '../Utilities/DataManager';
import { getAuthToken } from "../Utilities/StorageManager";

export function CardElement (props) {

    const {price, account_code, plan_code, history, pipioJSON, isRegistration, isLoggedIn, currentBillingInfo, currentCountry, noBillingInfo} = props;

        return (
                <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
                    <Elements>
                        { currentBillingInfo ? <CardForm history={history} account_code={account_code} plan_code={plan_code} pipioJSON={pipioJSON} isRegistration={isRegistration} isLoggedIn={isLoggedIn} currentBillingInfo={currentBillingInfo} price={price} currentCountry={currentCountry} noBillingInfo={noBillingInfo}
                                  fontSize={`18px`}/> : null }
                    </Elements>
                </RecurlyProvider>
        );
}


function CardForm (props) {

    const { account_code, plan_code, history, isRegistration, pipioJSON, isLoggedIn, currentBillingInfo, price, currentCountry, noBillingInfo } = props;

    let [ first_name, setStateFirstname ] = useState(currentBillingInfo.first_name);
    let [ last_name, setStateLastname ] = useState(currentBillingInfo.last_name);
    let [ street1, setStateStreet1 ] = useState(currentBillingInfo.address.street1);
    let [ city, setStateCity ] = useState(currentBillingInfo.address.city);
    let [ postal_code, setStatePostalCode ] = useState(currentBillingInfo.address.postal_code);
    let [ region, setStateRegion ] = useState(currentBillingInfo.address.region);
    let [ country, setCountry ] = useState(currentBillingInfo.address.country);

    let [ message, setStateMessage ] = useState('');

    let formValues = [];
    formValues["first_name"] = currentBillingInfo ? currentBillingInfo.first_name : "";
    formValues["last_name"] = currentBillingInfo ? currentBillingInfo.last_name : "";
    formValues["address1"] = currentBillingInfo ? currentBillingInfo.address.street1 : "";
    formValues["address2"] = currentBillingInfo ? currentBillingInfo.address.street2 : "";
    formValues["city"] = currentBillingInfo ? currentBillingInfo.address.city : "";
    formValues["postal_code"] = currentBillingInfo ? currentBillingInfo.address.postal_code : "";
    formValues["state"] = currentBillingInfo ? currentBillingInfo.address.region : "";
    formValues["country"] = currentBillingInfo ? currentBillingInfo.address.country : "";

    const recurly = useRecurly();
    let form = React.createRef();

    let businessNameMax = 100;
    let businessaddress_line_1Max = 100;
    let businessaddress_line_2Max = 100;
    let cityMax = 60;
    let zipMax = 9;

    let formErrors= [];
    formErrors["first_name"] = "";
    formErrors["last_name"] = "";
    formErrors["address1"] = "";
    formErrors["address2"] = "";
    formErrors["city"] = "";
    formErrors["postal_code"] = "";
    formErrors["state"] = "";
    formErrors["country"] = "";

    let formValidity= [];
    formValidity["first_name"] = false;
    formValidity["last_name"] = false;
    formValidity["address1"] = !currentCountry.address_line_1.required;
    formValidity["address2"] = !currentCountry.address_line_2.required;
    formValidity["city"] = !currentCountry.city.required;
    formValidity["postal_code"] = !currentCountry.zipcode.required;;
    formValidity["state"] = !currentCountry.state.required;;
    formValidity["country"] = true;

    let isSubmitting = false;

    let useMerchantAddress = false;

    // const handleBlur = () => console.log('[blur]');
    const handleChange = change => {
        document.getElementById("error_card").style.display = "none";
    };

    const handleChangeInput  = change => {

        formValues[change.target.name] = change.target.value;

        if (change.target.name == 'first_name') { setStateFirstname(formValues[change.target.name])}
        if (change.target.name == 'last_name') setStateLastname(formValues[change.target.name])
        if (change.target.name == 'address1') setStateStreet1(formValues[change.target.name])
        if (change.target.name == 'city') setStateCity(formValues[change.target.name])
        if (change.target.name == 'postal_code') setStatePostalCode(formValues[change.target.name])
        if (change.target.name == 'state') setStateRegion(formValues[change.target.name])
        if (change.target.name == 'country') setCountry(formValues[change.target.name])

        HandleValidationInput(change);
    };

    const HandleValidationInput = target => {

        const { name, value } = target.target;

        const fieldValidationErrors = formErrors;
        const validity = formValidity;

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : "ERROR";

        if (fieldValidationErrors[name] === "ERROR") {
            document.getElementById("error_" + name).style.display = "block";
        } else {
            document.getElementById("error_" + name).style.display = "none";
        }
        formErrors = fieldValidationErrors
        formValidity = validity
    };

    const handleSubmit = event => {

        if (event.preventDefault) event.preventDefault();
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
            }

        if (!isLoggedIn) {

            // Create billing info
            if (isSubmitting)
                return;

            isSubmitting = true;

            recurly.token(form.current, (err, token) => {
                if (err) {
                    console.log('[error]', err);
                    setStateMessage(err.message)
                    document.getElementById("error_card").style.display = "block";
                    isSubmitting = false;
                } else {
                    setMerchantAuthToken(getAuthToken())
                    createMerchantBillingInfo(account_code, token, plan_code, response => {
                        if (response === 'error') {
                            document.getElementById("error_updating_billing_info").style.display = "block";
                            isSubmitting = false;
                        } else {
                            console.log("Account created with billing info and subscription")
                            history.push('/MerchantCreationSuccess', {isRegistration: isRegistration});
                            isSubmitting = false;
                        }
                    });
                }
            });
        } else {
            // Update billing info
            if (isSubmitting)
                return;

            isSubmitting = true;

            recurly.token(form.current, (err, token) => {
                if (err) {
                    console.log('[error]', err);
                    setStateMessage(err.message)
                    document.getElementById("error_card").style.display = "block";
                    isSubmitting = false;
                } else {
                    setMerchantAuthToken(getAuthToken())
                    updateMerchantBillingInfo(account_code, token, response => {
                        if (response === 'error') {
                            document.getElementById("error_updating_billing_info").style.display = "block";
                            isSubmitting = false;
                        } else {
                            console.log("Account created with billing info and subscription")
                            history.push('/MerchantCreationSuccess', {isRegistration: false, pipioJSON});
                            isSubmitting = false;
                        }
                    });
                }
            });
        }
    };

    return (
        <form onSubmit={handleSubmit} ref={form}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text"
                                          id="first_name"
                                          maxLength={24}
                                          name="first_name"
                                          value={first_name}
                                          data-recurly="first_name"
                                          placeholder="First Name*"
                                          onChange={handleChangeInput}
                            />
                            <Form.Control.Feedback id='error_first_name' type="invalid">Please provide a first name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text"
                                          id="last_name"
                                          maxLength={24}
                                          name="last_name"
                                          value={last_name}
                                          data-recurly="last_name"
                                          placeholder="Last Name*"
                                          onChange={handleChangeInput}
                            />
                            <Form.Control.Feedback id='error_last_name' type="invalid">Please provide a last name.</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    { isRegistration || noBillingInfo ? (

                        <Form.Group className="custom-checkbox ml-0" controlId="formRememberPassword">
                            <Form.Check class="custom-control-input" type="checkbox" id="checkbox" label="Use my merchant address"
                                        onChange={() => {
                                            useMerchantAddress= !useMerchantAddress
                                            document.getElementById("checkbox").checked = useMerchantAddress;
                                            if (useMerchantAddress) {
                                                if ( document.getElementById("company") ) document.getElementById("company").value = pipioJSON.business_name;
                                                if ( document.getElementById("address1") ) document.getElementById("address1").value = pipioJSON.address_line_1;
                                                if ( document.getElementById("address2") ) document.getElementById("address2").value = pipioJSON.address_line_2;
                                                if ( document.getElementById("city") ) document.getElementById("city").value = pipioJSON.city;
                                                if ( document.getElementById("state") ) document.getElementById("state").value = pipioJSON.state;
                                                if ( document.getElementById("postal_code") ) document.getElementById("postal_code").value = pipioJSON.zipcode;
                                                if ( document.getElementById("country") ) document.getElementById("country").value = pipioJSON.country;
                                            } else {
                                                if ( document.getElementById("company") ) document.getElementById("company").value = '';
                                                if ( document.getElementById("address1") ) document.getElementById("address1").value = '';
                                                if ( document.getElementById("address2") ) document.getElementById("address2").value = '';
                                                if ( document.getElementById("city") ) document.getElementById("city").value = '';
                                                if ( document.getElementById("state") ) document.getElementById("state").value = 'Choose...*';
                                                if ( document.getElementById("postal_code") ) document.getElementById("postal_code").value = '';
                                                if ( document.getElementById("country") ) document.getElementById("country").value = '';
                                            }
                                        }}/>
                        </Form.Group>
                    ) : null }

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCompany">
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control type="text"
                                          maxLength={businessNameMax}
                                          name="company"
                                          id="company"
                                          defaultValue={currentBillingInfo ? currentBillingInfo.company : null}
                                          data-recurly="company"
                                          placeholder="Business Name"
                            />
                        </Form.Group>
                    </Form.Row>
                    { (currentCountry && currentCountry.address_line_1 && currentCountry.address_line_1.name) ? (
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label>{ currentCountry.address_line_1.name }</Form.Label>
                                <Form.Control type="text"
                                              maxLength={businessaddress_line_1Max}
                                              name="address1"
                                              value={street1}
                                              id="address1"
                                              data-recurly="address1"
                                              placeholder={ currentCountry.address_line_1.hint }
                                              onChange={handleChangeInput}
                                />
                                <Form.Control.Feedback id='error_address1' type="invalid">
                                    Please provide an { currentCountry.address_line_1.name.toLowerCase() }.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>
                    ) : null }
                    { currentCountry && currentCountry.address_line_2 && currentCountry.address_line_2.name ? (
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress2">
                                <Form.Label>{ currentCountry.address_line_2.name }</Form.Label>
                                <Form.Control type="text"
                                              maxLength={businessaddress_line_2Max}
                                              name="address2"
                                              defaultValue={currentBillingInfo ? currentBillingInfo.address.street2 : null}
                                              id="address2"
                                              data-recurly="address2"
                                              placeholder={ currentCountry.address_line_2.hint }
                                />
                            </Form.Group>
                        </Form.Row>
                    ) : null }

                        <Form.Row>
                            { currentCountry && currentCountry.city && currentCountry.city.name ? (
                                <Form.Group as={Col} md="6" controlId="formGridCity">
                                    <Form.Label>{ currentCountry.city.name }</Form.Label>
                                    <Form.Control type="text"
                                                  maxLength={cityMax}
                                                  name="city"
                                                  value={city}
                                                  id="city"
                                                  data-recurly="city"
                                                  placeholder={ currentCountry.city.hint }
                                                  onChange={handleChangeInput}
                                    />
                                    <Form.Control.Feedback id='error_city' type="invalid">
                                        Please provide a { currentCountry.city.name.toLowerCase() }.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : null }
                            { currentCountry && currentCountry.state && currentCountry.state.name ? (
                                <Form.Group as={Col} md="3" controlId="formGridState">
                                        <Form.Label>{ currentCountry.state.name }</Form.Label>
                                        <Form.Control as="select"
                                                      name="state"
                                                      id="state"
                                                      data-recurly="state"
                                                      value={region}
                                                      onChange={handleChangeInput}
                                                      >
                                            <option>{region ? region : "Choose...*"}</option>
                                            {currentCountry.state.options.map((e, key) => {
                                                return <option key={key} value={e}>{e}</option>;
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback id='error_state' type="invalid">
                                            Please select a { currentCountry.state.name.toLowerCase() }.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            ) : null }
                            { currentCountry && currentCountry.zipcode && currentCountry.zipcode.name ? (
                                <Form.Group as={Col} md="3" controlId="formGridZip">
                                    <Form.Label>{ currentCountry.zipcode.name }</Form.Label>
                                    <Form.Control name="postal_code"
                                                  id="postal_code"
                                                  value={postal_code}
                                                  maxLength={zipMax}
                                                  type={"text"}
                                                  data-recurly="postal_code"
                                                  placeholder={ currentCountry.zipcode.hint }
                                                  onChange={handleChangeInput}
                                                  />
                                    <Form.Control.Feedback id='error_postal_code'  type="invalid">
                                        Please provide a { currentCountry.zipcode.name.toLowerCase() } .
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : null }
                        </Form.Row>
                        <Form.Group as={Col} controlId="formGridCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control name="country"
                                          id="country"
                                          data-recurly="country"
                                          placeholder={ pipioJSON.country }
                                          defaultValue={ pipioJSON.country }
                                          disabled={true}
                            />
                        </Form.Group>

                    <h3>Card details</h3>

                    <Form.Row>
                            <Form.Group as={Col} controlId="formGridZip">
                                <img alt="Visa" src="./img/Cards/visa.svg" hspace="3" vspace="3" />
                                <img alt="Mastercard" src="./img/Cards/mastercard.svg" hspace="3" vspace="3" />
                                <img alt="Amex" src="./img/Cards/amex.svg" hspace="3" vspace="3" />
                                <img alt="Discover" src="./img/Cards/discover.svg" hspace="3" vspace="3" />
                                <CardNumberElement
                                    name="cardnumber"
                                    //className="recurly-element-inline"
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    style={{ placeholder: { content: currentBillingInfo ? currentBillingInfo.payment_method.cardnumber : "Card number" } }}
                                />
                                <CardMonthElement
                                    //className="recurly-element-inline"
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    style={{ placeholder: { content: "MM" } }}
                                />
                                <CardYearElement
                                    //className="recurly-element-inline"
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    style={{ placeholder: { content: "YY" } }}
                                />
                                <CardCvvElement
                                    //className="recurly-element-inline"
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    style={{ placeholder: { content: "CVV" } }}
                                />

                                <Form.Control.Feedback id='error_card'  type="invalid">
                                    {message.substring(0, message.length - +(message.lastIndexOf('.') == message.length -1 ))}. Please provide review your card detail information.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback id='error_updating_billing_info'  type="invalid">
                                    Error updating billing information. Please try again.
                                </Form.Control.Feedback>
                            </Form.Group>
                        <small>
                            By clicking the  { !isLoggedIn ? ( '"Pay Subscription"' ) : ( '"Update Billing Info"' ) } button below, you agree to our  <a href="https://www.getpipio.com/policies.html#tos" target="new">Terms of Service</a>, <a href="https://www.getpipio.com/policies.html#privacy" target="new">Privacy Policy</a>, that you are over 18, and that Panopt.io <strong>will automatically continue your subscription at the end of your free trial and charge the subscription fee (currently { price  }) to your payment method on a monthly basis until you cancel. You may cancel at any time during your free trial and will not be charged</strong>. To cancel, go online at <a href="https://app.pipioapp.com/MerchantSignIn" target="new">app.pipioapp.com</a> and Sign In to your Account and click “Cancel” on your subscription. There are no refunds or credits for partial months.
                        </small>
                        <br/>
                    </Form.Row>
                    <div className="text-center">
                        { !isLoggedIn ? (
                            <Button className="btn btn-primary mb-2 px-5" name="PaySubscription"  type="submit">
                                Pay subscription
                            </Button>
                        ) : (
                            <Button className="btn btn-primary mb-2 px-5" name="UpdateBilling"  type="submit">
                                Update Billing Info
                            </Button>
                        )}

                    </div>
                </form>
    );
}
