import React, { Component } from 'react';
import { Button, Alert} from 'react-bootstrap';

export default class Alert2Buttons extends Component {

    render() {
        const { typeAlert, titleAlert, msgAlert, button1onClick, button2onClick, button1Caption, button2Caption } = this.props;


        return (

                <Alert variant={typeAlert}>
                    { titleAlert ? (
                        <Alert.Heading>{titleAlert}</Alert.Heading>
                    ) : null }
                    { msgAlert ? (
                        <p>
                            {msgAlert}
                        </p>
                    ) : null }
                    <hr />
                    <div className="d-flex justify-content-end">
                        { button1Caption ? (
                            <Button onClick={button1onClick} variant="outline-success">
                                {button1Caption}
                            </Button>
                        ) : null }
                        { button2Caption ? (
                            <Button onClick={button2onClick} variant="outline-success">
                                {button2Caption}
                            </Button>
                            ) : null }
                    </div>
                </Alert>
        );
    }
}
