import React, { Component } from 'react';

export default class Header extends Component {

    render() {

        return (
            <footer>
                <div className="container">
                    <p>&copy; Panopt.io 2020. All Rights Reserved.</p>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="https://www.getpipio.com/policies.html#privacy" target="new">Privacy</a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.getpipio.com/policies.html#tos" target="new">Terms</a>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}
