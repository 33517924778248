import React from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import {Formik} from "formik";
import * as yup from "yup";

import { registerUser, setMerchantAuthToken } from './Utilities/DataManager';
import { storeUserId, storeAuthToken } from './Utilities/StorageManager';
import { isValidPassword } from './Utilities/Utils';
import Alert2Buttons from './_library/Alert2Buttons';

import ModalCustom from './_library/ModalCustom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "./_library/Header";
import Footer from "./_library/Footer";

class MerchantSignUp extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            formValues: {
                email: "",
                password1: "",
                password2: ""
            },
            formErrors: {
                email: "",
                password1: "",
                password2: ""

            },
            formValidity: {
                email: false,
                password1: false,
                password2: false,
            },
            typeAlert: "",
            titleAlert: "",
            msgAlert: "",
            button1onClick: "",
            button2onClick: "",
            button1Caption: "",
            button2Caption: "",
            showAlert: false,
            showHelp: false,

        };

    }

    pipioJSON = {
        password: '',
        email: '',
        agreed: false,
        subscription: undefined,
        category: 0,
        sub_categories: [],
        business_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        business_description: '',
        longitude: 1.0,
        latitude: 1.0,
        //subscription_expiration: ''
    };

    schema = yup.object({
        email: yup.string().required(),
        password1: yup.string().required(),
        password2: yup.string().required(),
    });

    isSubmitting = false;

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isEmail = name === "email";
        const isPassword2 = name === "password2";
        const isPassword1 = name === "password1";

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : "ERROR";


        if (validity[name]) {
            if (isEmail) {
                validity[name] = emailRegex.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }

            if (isPassword1) {
                validity[name] = isValidPassword(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }

            if (isPassword2) {
                validity[name] = (this.state.formValues["password1"] == value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : "ERROR";
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    handleSubmit = event => {

        event.preventDefault();
        const {formValues, formValidity} = this.state;

        //this.goProfile();

        if (Object.values(formValidity).every(Boolean)) {

            this.signUp();
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                this.handleValidation(target);

            }
        }
    }

    handleChange = ({ target }) => {

        const { formValues } = this.state;
        formValues[target.name] = target.value;
        this.setState({ formValues });
        this.handleValidation(target);
    };

    goProfile() {
        this.props.history.push( '/MerchantPayment' , { pipioJSON : this.pipioJSON, isRegistration: true });
    }
    signUp() {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        this.pipioJSON.email = this.state.formValues['email'];
        this.pipioJSON.password = this.state.formValues['password1'];
        // Agreed T&Cs by going forward.
        this.pipioJSON.agreed = true;

        registerUser(this.pipioJSON, (userData) => {
            if (!userData) {
                this.isSubmitting = false;
                return;
            } else if (userData === 'error') {
                this.setState( {typeAlert: "danger", titleAlert: "Error", msgAlert: "Could not register", button1onClick: "", button2onClick: "", button1Caption: "", button2Caption: ""})
                this.isSubmitting = false;
                return;
            } else if (userData === 'exists') {
                this.alertUserExists();
                this.isSubmitting = false;
                return;
            }  else if (userData === 'passwordcriteria') {
                this.alertPasswordCriteria();
                this.isSubmitting = false;
                return;
            }

            setMerchantAuthToken(userData.auth_token);
            storeUserId(userData.id);
            storeAuthToken(userData.auth_token);
            this.isSubmitting = false;

            // No need to store the password in pipioJSON at this point. Clearing it.
            this.pipioJSON.password = ''
            this.props.history.push( '/MerchantConfirmation' , { pipioJSON : this.pipioJSON, isRegistration: true });

        });
    }

    showAlert() {
        this.setState( { showAlert : false} )
    }

    alertUserExists() {
        this.setState({
            showAlert: true,
            typeAlert: "danger",
            titleAlert: "User already exists",
            msgAlert: "A User with the provided email already exists.",
            button2onClick: "",
            button1Caption: "Ok"
        })
    }

    alertPasswordCriteria() {
        this.setState( {showAlert: true,
            typeAlert: "danger",
            titleAlert: "Password does not meet complexity requirements",
            msgAlert: "Your Password may be too short or too simple.",
            button1Caption: "Ok",
            button2Caption: ""
        })
    }


    render() {

        const { formErrors, showAlert, typeAlert, titleAlert, msgAlert, button1onClick, button2onClick, button1Caption, button2Caption } = this.state;
        return (
            <div>
              <Header title="Merchant account - Sign up" isRegistration={true}/>

              <section className="pricing py-6">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-xl-9 mx-auto">
                      <div className="card card-signin flex-row">
                          <div className="card-img-left d-none d-md-flex">
                          </div>
                          <div className="card-body text-center">
                            <h3 className="login-heading mb-4">Register a new account!</h3>
                            <Formik
                                validationSchema={this.schema}
                                onSubmit={console.log}
                                initialValues={{}}>
                                {({
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      values,
                                      touched,
                                      isValid,
                                      errors,
                                  }) => (

                                  <Form onSubmit={this.handleSubmit}>

                                      <ModalCustom show={this.state.showHelp} title="Password requirements"
                                        body={
                                          <div style={{ padding: 10 }}>
                                            <h5>Requirements:</h5>
                                            <ul>
                                              <li>8 characters minimum; 12 recommended.</li>
                                              <li>No more than 3 consecutive characters.</li>
                                            </ul>

                                            <h5>Recommendations:</h5>
                                            <ul>
                                              <li>Combine words into a phrase to encourage memorization.</li>
                                              <li>Use a password manager to generate a random password.</li>
                                            </ul>

                                            <h5>Examples:</h5>
                                            <ul>
                                              <li>EXCELLENT:
                                                <ul><li>l00kingf0rSom3th1nggr34T</li></ul>
                                              </li>
                                              <li>FAIR:
                                                <ul><li>lookingforsomethinggreat</li></ul>
                                              </li>
                                              <li>POOR:
                                                <ul><li>aaabbbcccddd</li></ul>
                                              </li>
                                            </ul>
                                          </div>
                                        }
                                        button1Caption={"Ok"}
                                        button1Action={ () => {this.setState( {showHelp: false} ) }}
                                        onHide={ () => {this.setState( {showHelp: false} ) }}
                                      />

                                      <Form.Group as={Col} controlId="formGridEmail">

                                          <Form.Control type="email"
                                                        name="email"
                                                        autoCapitalize={'none'}
                                                        placeholder="Email address*"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.email}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                              Please provide a valid email address.
                                          </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group as={Col} controlId="formGridPassword">
                                          <Form.Control type="password"
                                                        name="password1"
                                                        placeholder="Choose password*"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.password1}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                              The password does not meet the requirements. Please check the password tooltip for more information.
                                          </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group as={Col} controlId="formGridPassword">
                                          <Form.Control type="password"
                                                        name="password2"
                                                        placeholder="Repeat password*"
                                                        onChange={this.handleChange}
                                                        isInvalid={!!formErrors.password2}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                              Please provide passwords or make sure both passwords match.
                                          </Form.Control.Feedback>
                                          <FontAwesomeIcon id='Icon' style={{padding: 10}} icon={faQuestionCircle} size="3x" pull="left" onClick={ () => this.setState( {showHelp: true})}/>
                                      </Form.Group>

                                      { showAlert ? (
                                          <Alert2Buttons typeAlert={typeAlert} titleAlert={titleAlert}
                                                         msgAlert={msgAlert} button1onClick={() => this.showAlert()}
                                                         button2onClick={button2onClick} button1Caption={button1Caption}
                                                         button2Caption={button2Caption} />
                                              ) : null }
                                      <Button className="btn btn-primary mb-2 px-5" variant="primary" type="submit">
                                              Next - email verification
                                      </Button>
                                  </Form>
                                )}
                              </Formik>
                              Already have an account? <a href="/MerchantSignIn">Sign In</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </div>
        );
    }
}

export default MerchantSignUp;
