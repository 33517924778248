//Constants for backing up data
const JSON_KEY = 'flitterJSON.json';
const JSON_KEY_ID = 'flitterJSON.id';
const BACKUP_JSON_KEY = 'flitterJSON.json.bak';
const BACKUP_JSON_KEY_ID = 'flitterJSON.id.bak';
const THEME_KEY = 'themeName';
const EMAIL_KEY = 'merchantemail';
const CONSUMER_PREFS_KEY = 'consumerprefs';
const REGISTER_DEVICE = 'register-device';
const AUTH_TOKEN = 'auth_token';
const USER_ID = 'user_id';
const APP_PURPOSE = 'app_purpose';
const CONSUMER_METRIC_PREFS = 'metrics_prefs';
const DAY_WEEK_NOTIFICATIONS = 'day_week_notifications';
const WAKE_TIME = 'wake_time';
const MAX_NOTIFICATIONS_PER_DAY = 'max_notifications_per_day';

export function storeDocument(doc, DOC_TYPE, callback) {
    localStorage.setItem(DOC_TYPE, JSON.stringify(doc))
}

export function retrieveDocument(DOC_TYPE, callback) {

    console.log('Retrieving local copy for ' + DOC_TYPE)
    localStorage.getItem(DOC_TYPE)
        .then(value => {
            if (!value) {
                value = JSON.stringify({
                    "date": "",
                    "summary": "",
                    "paragraphs": [
                        {
                            "heading": "",
                            "copy": ""
                        }
                    ]
                })
            }
            callback(JSON.parse(value));
        }).catch(error => {
            let value = JSON.stringify({
                "date": "",
                "summary": "",
                "paragraphs": [
                    {
                        "heading": "",
                        "copy": ""
                    }
                ]
            })
            callback(JSON.parse(value));
        }
    );
}

/* Store preference to register device - consume mode here too */
export function storeRegisterDevice(value) {
    localStorage.setItem(REGISTER_DEVICE, value)
        .then(() => console.log('saved new successfully to ' + value))
        .catch(error => console.error(error));
}

export function isRegisterDevice() {
    localStorage.getItem(REGISTER_DEVICE)
        .then(value => {
            console.log('isRegisterDevice:' + value);
            return value;
        })
        .catch(error => console.error(error));
}

/* Store Application purpose: 1 Consumer, 2 Merchant or 2 ConsumerMerchant */
export function storeApplicationPurpose(value) {
    localStorage.setItem(APP_PURPOSE, value.toString())
        .then(() => console.log('saved application purpose to ' + value))
        .catch(error => console.error(error));
}

export function retrieveApplicationPurpose(callback) {
    localStorage.getItem(APP_PURPOSE)
        .then(value => {
            console.log('Retrieved Application purpose:' + value);
            if (value != null)
                callback(parseInt(value, 10));
            else
                callback('error')
        }).catch(error => {callback('error')});
}

/* Store Consumer Metrics preferences */
export function storeMetricsConsumerPrefs(unit, defaultvalue) {

    const units_kms   =   [ 0.5,  1,    2  , 4,   6, 8, 10,  12, 16, 18,   20, 24 ]
    const units_miles =   [ 0.25, 0.75, 1.5, 2.5, 4, 5,  6, 7.5, 10, 11, 12.5, 15 ]

    let document = {
        metrics: ( unit ),
        default: defaultvalue,
        defaultInMeters: ( (unit === 'km' ? defaultvalue * 1000 : units_kms[ units_miles.findIndex(element => element >= defaultvalue) ] * 1000 ))
    }
    localStorage.setItem(CONSUMER_METRIC_PREFS, JSON.stringify(document))
        .then(() => console.log('saved Consumer Metrics Prefs ' + JSON.stringify(document)))
        .catch(error => console.error(error));
}

export function retrieveMetricsConsumerPrefs(callback) {

    localStorage.getItem(CONSUMER_METRIC_PREFS)
        .then(value => {
            if (!value) {
                value = JSON.stringify({
                    metrics: 'mi',
                    default: 15,
                    defaultInMeters: 24000
                })
            }
            callback(JSON.parse(value));
        }).catch(error => {
            let value = {
                metrics: 'mi',
                default: 15,
                defaultInMeters: 24000
            }
            callback(value);
        }
        );
}

/* Store Day Week Notifications */
export function storeDayWeekNotifications(dayweeknotification) {
    localStorage.setItem(DAY_WEEK_NOTIFICATIONS, JSON.stringify(dayweeknotification))
        .then(() => console.log('saved Day Week Notifications ' + JSON.stringify(dayweeknotification)))
        .catch(error => console.error(error));
}

export function retrieveDayWeekNotifications(callback) {
    localStorage.getItem(DAY_WEEK_NOTIFICATIONS)
        .then(value => {
            if (!value) {
                value = JSON.stringify({
                    "DOW": [1,2,3,4,5,6,7]
                })
            }
            console.log(value)
            callback(JSON.parse(value));
        }).catch(error => {
            let value = JSON.stringify({
                "DOW": [1,2,3,4,5,6,7]
            })
            callback(JSON.parse(value));
        }
    );
}



/* Store WAKE TIME */
export function storeWakeTime(wake_time, period) {
    let doc = {
        "wake_time": wake_time,
        "period": period
    }

    localStorage.setItem(WAKE_TIME, JSON.stringify(doc))
        .then(() => console.log('saved Wake Time ' + JSON.stringify(doc)))
        .catch(error => console.error(error));
}

export function retrieveWakeTime(callback) {
    localStorage.getItem(WAKE_TIME)
        .then(value => {
            if (!value) {
                value = JSON.stringify({
                    "wake_time": "none"
                })
            }
            callback(JSON.parse(value));
        }).catch(error => {
            let value = JSON.stringify({
                "wake_time": "none"
            })
            callback(JSON.parse(value));
        }
    );
}

/* Store auth token on device */
export function storeAuthToken(value) {
    localStorage.setItem(AUTH_TOKEN, value)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN);

}

/* Store auth token on device */
export function storeUserId(value) {
    localStorage.setItem(USER_ID, value.toString());
}

export function getUserId(callback) {
    callback(localStorage.getItem(USER_ID));
    return;
}

export function storeConsumerPrefs(prefs) {
    /*
    *  consumerprefs document
    *  {
    *       "categories": [ ],
    *       "sub_categories": [ ],
    *       "favorites": [ ],
    *       "blacklist": [ ]
    *  }
    *
    * */

    getConsumerPrefs(exising_prefs => {
        if (exising_prefs) {
            prefs = {...exising_prefs, ...prefs}

            localStorage.setItem(CONSUMER_PREFS_KEY, JSON.stringify(prefs))
                .then(() => {

                    if (prefs.categories) console.log('Stored consumer preference with pref categories :' + prefs.categories.toString())
                    if (prefs.sub_categories) console.log('Stored consumer preference with pref sub_categories : ' + prefs.sub_categories.toString())
                    if (prefs.favorites) console.log('Stored consumer preference with pref favorites : ' + prefs.favorites)
                    if (prefs.blacklist) console.log('Stored consumer preference with pref blacklist : ' + prefs.blacklist)
                })
                .catch(error => console.error(error));
        } else {
            localStorage.setItem(CONSUMER_PREFS_KEY, JSON.stringify(prefs))
                .then(() => {

                    if (prefs.categories) console.log('Stored consumer preference with pref categories :' + prefs.categories.toString())
                    if (prefs.sub_categories) console.log('Stored consumer preference with pref sub_categories : ' + prefs.sub_categories.toString())
                    if (prefs.favorites) console.log('Stored consumer preference with pref favorites : ' + prefs.favorites)
                    if (prefs.blacklist) console.log('Stored consumer preference with pref blacklist : ' + prefs.blacklist)
                })
                .catch(error => console.error(error));
        }
    });
}


export function getConsumerPrefs(callback) {

    /*
    *  consumerprefs document
    *  {
    *       "categories": [ ],
    *       "sub_categories": [ ],
    *       "favorites": [ ],
    *       "blacklist": [ ]
    *  }
    *
    * */

    callback(localStorage.getItem(CONSUMER_PREFS_KEY));
    return;
}

export async function retrieveItem(key) {
    try {
        const retrievedItem =  await localStorage.getItem(key);
        console.log('retrievedItem:' + retrievedItem);
        return retrievedItem;
    } catch (error) {
        console.log(error.message);
    }
    return
}

/*
Creates a backup of stored JSON data
Stores a new backup with the passed JSON data
*/
export function backupJSON(flitterJSON, merchant_id) {
    //Copy original to backup location
    localStorage.getItem(JSON_KEY)
        .then(value => {
            if (!value) { //There was no flitter.json found
                return;
            }

            localStorage.setItem(BACKUP_JSON_KEY, value)
                .then(() => console.log('saved backup successfully'))
                .catch(error => console.error(error));
        })
        .catch(error => console.error(error));

    //backup newly downloaded/validated
    localStorage.setItem(JSON_KEY, JSON.stringify(flitterJSON))
        .then(() => console.log('saved new flitterJSON successfully'))
        .catch(error => console.error(error));


    localStorage.getItem(JSON_KEY_ID)
        .then(value => {
            if (!value) { //There was no flitter.json found
                return;
            }

            localStorage.setItem(BACKUP_JSON_KEY_ID, value)
                .then(() => console.log('saved backup successfully'))
                .catch(error => console.error(error));
        })
        .catch(error => console.error(error));

    //backup newly downloaded/validated
    localStorage.setItem(JSON_KEY_ID, merchant_id.toString())
        .then(() => console.log('saved new merchant id ' + merchant_id.toString() + ' successfully'))
        .catch(error => console.error(error));

}

// retrieve stored merchant id
export function retrieveMerchantId(callback) {
    localStorage.getItem(JSON_KEY_ID)
        .then(value => {
            if (!value) { //No backup found
                return 'error';
            }
            console.log('retrieveMerchantId ' + value)
            callback(JSON.parse(value));
        });
}

/*
Gets called if the fetch request failed
Loads and makes a callback with backuped flitter data
*/
export async function retrieveJSON() {
    localStorage.getItem(JSON_KEY)
        .then(value => {
            if (!value) { //No backup found
                return 'error';
            }
            return JSON.parse(value);
        });
}

export function backupTheme(themeName) {
    localStorage.setItem(THEME_KEY, themeName)
        .then(() => console.log('saved theme successfully'))
        .catch(error => console.error(error));
}

export function retrieveTheme(callback) {
    localStorage.getItem(THEME_KEY)
        .then(value => {
            if (!value) { //No backup found
                callback('Smoky');
                return;
            }
            callback(value);
        })
        .catch(error => callback('Smoky'));
}

export function backupMerchantEmail(email) {
    localStorage.setItem(EMAIL_KEY, email)
}

export function retrieveMerchantEmail(callback) {
    callback(localStorage.getItem(EMAIL_KEY));
    return;
}

/* Store Day Week Notifications */
export function storeMaxNotificationsPerDay(maxnotificationsperday) {

    let doc = {
        "maxNotificationsPerDay": maxnotificationsperday
    }

    localStorage.setItem(MAX_NOTIFICATIONS_PER_DAY, JSON.stringify(doc))
        .then(() => console.log('saved Max Notifications Per Day' + JSON.stringify(doc)))
        .catch(error => console.error(error));
}

export function retrieveMaxNotificationsPerDay(callback) {

    localStorage.getItem(MAX_NOTIFICATIONS_PER_DAY)
        .then(value => {
            if (!value) {
                value = JSON.stringify({
                    "maxNotificationsPerDay": 99
                })
            }
            callback(JSON.parse(value));
        }).catch(error => {
            let value = JSON.stringify({
                "maxNotificationsPerDay": 99
            })
            callback(JSON.parse(value));
        }
    );
}
