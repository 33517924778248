
import React, { Component } from 'react';
import { Button, Modal} from 'react-bootstrap';

export default class ModalCustom extends Component {

    render() {

        const { body, title, show, onHide, button1Caption, button1Action, button2Caption, button2Action } = this.props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <div className="col-sm-12 text-center">
                    { button1Caption ? (
                        <Button style={{width: 100}} variant="secondary" onClick={button1Action}>
                            {button1Caption}
                        </Button>
                    ) : null}
                    { button2Caption ? (
                        <Button style={{width: 100}} variant="primary" onClick={button2Action}>
                            {button2Caption}
                        </Button>
                    ) : null}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
