import moment from 'moment';

// Check Subscription Expiration
export function subscriptionExpired(merchantJSON) {

    // Is plan ACTIVE?
    if (!merchantJSON.is_active) {
        return true;
    }

    // Is it a BETA plan?
    if (merchantJSON.subscription === 1) {
        return false;
    }

    // Let's check how now()'s date compare with merchant.subscription_expiration
    const now = moment();
    const expired = moment.utc(merchantJSON.subscription_expiration).local();
    return now > expired;
}

export function getAddressFromAddressComponents(addressComponents) {
    const address = {
        city: '',
        state: '',
        zip: '',
        addressLine1: '',
    };
    addressComponents.forEach(component => {
        const types = component.types;
        if (types.includes('locality')) {
            address.city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
            address.state = component.short_name;
        } else if (types.includes('postal_code')) {
            address.zip = component.long_name;
        } else if (types.includes('street_number')) {
            address.addressLine1 = component.long_name + address.addressLine1;
        } else if (types.includes('route')) {
            address.addressLine1 += ` ${component.long_name}`;
        }
    });

    return address;
}

export function isValidPassword(password) {
    if (password.length < 8) {
        return false;
    }

    let char = password[0];
    let count = 1;
    for (let i = 1; i < password.length; i++) {
        if (char === password[i]) {
            count++;
        } else {
            count = 1;
        }
        if (count === 3) {
            return false;
        }
        char = password[i];
    }
    return true;
}

// Return day of the week
export function getDayOfWeek() {
    const dayOfWeek = new Date().getDay();
    return isNaN(dayOfWeek) ? null : dayOfWeek + 1;
}
