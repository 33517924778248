import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    getSubscriptionPlans, getEventTypes,
    getSalesTypes, getMerchantById,
    changeMerchantSubscription, setMerchantAuthToken, retrieveMerchantBillingInfo, createMerchantBillingInfo,
    cancelMerchantSubscription, retrieveMerchantSubscription, reactivateMerchantSubscription, logout
} from './Utilities/DataManager';
import { getAuthToken, storeAuthToken } from './Utilities/StorageManager';

import Header from "./_library/Header";
import Footer from "./_library/Footer";

import { Button} from 'react-bootstrap';
import ModalCustom from "./_library/ModalCustom";
import moment from 'moment';
import IdleTimer from 'react-idle-timer'

class MerchantSubscription extends React.Component {

    constructor(props) {
        super(props);

        this.idleTimer = null
        this.onIdle = this._onIdle.bind(this)

        this.state = {
            plans: null,
            event_types: [],
            sales_types: [],
            pipioJSON: undefined,
            showConfirmCancel: false,
            msg: false,

            has_subscription: false,
            canceled: false,
            date_expiry: ''
        }

    }

    seconds = process.env.REACT_APP_IDLE_TIMEOUT ? process.env.REACT_APP_IDLE_TIMEOUT : 180
    plans = [];
    event_types = [];
    sale_types = [];
    isSubmitting = false;
    stopIdleTimer= false;

    componentDidMount(){

        try {

            if (this.props.history.location.state && this.props.history.location.state.isRegistration)
                this.stopIdleTimer= true;

            const fromSignIn = this.props.history.location.state.fromSignIn;
            const merchant_id = this.props.history.location.state.merchant_id;

            getMerchantById( merchant_id, merchantRes => {
                if (merchantRes && merchantRes !== 'error' && merchantRes !== 'retry') {
                    setMerchantAuthToken(getAuthToken())
                    retrieveMerchantSubscription(merchantRes.merchant_id, fromSignIn,recurly_response => {
                        if (recurly_response && recurly_response !== 'error' && recurly_response !== 'nosubscription' && recurly_response !== 'retry') {
                            if (recurly_response.Subscription.state !== 'canceled') {
                                // IMPORTANT - Master is recurly for this here.
                                if (moment.utc() < moment.utc(recurly_response.Subscription.expiry)) {
                                    merchantRes.subscription = recurly_response.Subscription.plan_code
                                    merchantRes.subscription_expiration = recurly_response.Subscription.expiry
                                    this.state.has_subscription = true
                                    this.setState( { date_expiry: moment.utc(recurly_response.Subscription.expiry).local().format("MMMM DD, YYYY") } )
                                } else {
                                    merchantRes.subscription = undefined
                                    merchantRes.subscription_expiration = undefined
                                    this.state.has_subscription = false
                                }
                            } else {
                                merchantRes.subscription = recurly_response.Subscription.plan_code
                                merchantRes.subscription_expiration = recurly_response.Subscription.expiry
                                this.setState({ canceled : true , date_expiry: moment.utc(recurly_response.Subscription.expiry).local().format("MMMM DD, YYYY") })
                            }
                        } else {
                            // no subscription found so overwritting what this may have.
                            merchantRes.subscription = undefined
                            merchantRes.subscription_expiration = undefined
                            this.state.has_subscription = false
                        }

                        this.setState( { pipioJSON : merchantRes } )

                        // Get list of plans
                        getSubscriptionPlans((response) => {

                            if (response !== 'error') {
                                this.plans = response;
                                getEventTypes((eventtypes) => {
                                    if (eventtypes !== 'error') {
                                        this.event_types = eventtypes
                                        getSalesTypes((salestypes) => {
                                            if (salestypes !== 'error') {
                                                this.sale_types = salestypes
                                                if (!fromSignIn) {
                                                    this.setState({
                                                        pipioJSON: this.props.history.location.state.pipioJSON,
                                                        plans: this.plans,
                                                        sale_types: this.sale_types,
                                                        event_types: this.event_types
                                                    })
                                                } else {
                                                    this.setState({
                                                        plans: this.plans,
                                                        sale_types: this.sale_types,
                                                        event_types: this.event_types
                                                    })
                                                }
                                            }
                                        })
                                    }
                                });
                            }
                        });
                    });
                }
            })

        } catch (err) {
            console.warn(err.code, err.message);
            this.props.history.push('/MerchantSignIn')
        }

    }

    cancelSubscription() {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;
        let {pipioJSON} = this.state

        setMerchantAuthToken(getAuthToken())
        cancelMerchantSubscription(pipioJSON.merchant_id, response => {
            if (response && response !== 'error' && response !== 'retry') {
                this.setState({ date_expiry: moment.utc(response.subscription_ends).local().format("MMMM DD, YYYY"), canceled: true, showConfirmCancel: true, msg: "Your subscription is now canceled and will terminate on " + moment.utc(response.subscription_ends).local().format("MMMM DD, YYYY") + ". Thank you for being a merchant and working with us."})
                this.isSubmitting = false;
            } else {
                this.setState({ has_subscription: false, showConfirmCancel: true, msg: "Unable to cancel your subscription. Please try again later."})
                this.isSubmitting = false;
            }
        });
    }

    submitReActivatePlan(planId) {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        let { pipioJSON } = this.state
        reactivateMerchantSubscription( pipioJSON.merchant_id, response => {
            if (response && response !== 'error' && response !== 'retry') {
                this.setState({ has_subscription: true, date_expiry: moment.utc(response.Subscription).local().format("MMMM DD, YYYY"), canceled: false, showConfirmCancel: true, msg: "Your plans has been re-activated. Thank you!"})
            }
            this.isSubmitting = false;
        })

    }

    submitChosenPlan(planId) {

        if (this.isSubmitting)
            return;

        this.isSubmitting = true;

        let { pipioJSON, has_subscription } = this.state
        const fromSignIn = this.props.history.location.state.fromSignIn;

        if (!fromSignIn) {

            if (planId) {

                pipioJSON.subscription = planId
                pipioJSON.subscription_expiration = new Date(Date.now() + (1 * 24 * 60 * 60 * 1000));
                if (planId > 1) {

                    if (planId != 2)
                        // Paying subscription
                        this.props.history.push( '/MerchantPayment' , { pipioJSON, isRegistration: this.props.history.location.state.isRegistration, currentCountry: this.props.history.location.state.currentCountry });
                    else {
                        createMerchantBillingInfo(pipioJSON.merchant_id, { id: 'TOKEN' }, planId, response => {
                            if (response !== 'error')
                                this.props.history.push('/MerchantCreationSuccess', {isRegistration: this.props.history.location.state.isRegistration, isFreePlan: true});
                        })
                    }

                } else {
                    // App is free here - no subscription
                    this.props.history.push( '/MerchantPayment' , { pipioJSON, isRegistration: this.props.history.location.state.isRegistration, currentCountry: this.props.history.location.state.currentCountry });
                }
            }

        } else {
            // upgrade or downgrade of subscription here.
            if (planId) {

                // Update Recurly Subscription
                setMerchantAuthToken(getAuthToken())
                retrieveMerchantBillingInfo(pipioJSON.merchant_id, response1 => {
                    if (response1 !== 'error') {
                        if (response1.payment_method.cardnumber !== '') {
                            if (has_subscription) {
                                changeMerchantSubscription(pipioJSON.merchant_id, planId, response => {
                                    if (response && response !== 'error' && response !== 'retry') {
                                        this.setState({
                                            showConfirmCancel: true,
                                            msg: "Your plans has been changed thank you!"
                                        })
                                        pipioJSON.subscription = planId
                                        pipioJSON.subscription_expiration = new Date(Date.now() + (1 * 24 * 60 * 60 * 1000));
                                        this.isSubmitting = false;
                                    } else {
                                        this.setState({
                                            showConfirmCancel: true,
                                            msg: "An error has occurred selecting this plan. This happens if a plan has been cancelled but is not expired yet."
                                        })
                                        this.isSubmitting = false;
                                    }

                                })
                            } else {
                                pipioJSON.subscription = planId
                                this.props.history.push('/MerchantPayment', {
                                    pipioJSON,
                                    isRegistration: this.props.history.location.state.isRegistration
                                });
                                this.isSubmitting = false;
                            }
                        } else  {
                            if (planId != 2) {
                                pipioJSON.subscription = planId
                                pipioJSON.subscription_expiration = new Date(Date.now() + (1 * 24 * 60 * 60 * 1000));
                                if (planId > 1) {
                                    // Paying subscription
                                    this.props.history.push('/MerchantPayment', {
                                        pipioJSON,
                                        isRegistration: this.props.history.location.state.isRegistration,
                                        currentCountry: this.props.history.location.state.currentCountry
                                    });
                                } else {
                                    // App is free here - no subscription
                                    this.props.history.push('/MerchantPayment', {
                                        pipioJSON,
                                        isRegistration: this.props.history.location.state.isRegistration,
                                        currentCountry: this.props.history.location.state.currentCountry
                                    });
                                }
                            } else {
                                createMerchantBillingInfo(pipioJSON.merchant_id, 'TOKEN', planId, response => {
                                    if (response !== 'error')
                                        this.props.history.push('/MerchantCreationSuccess', {isRegistration: this.props.history.location.state.isRegistration, isFreePlan: true});

                                })
                            }
                        }
                    }
                })
            }
        }

    }

    convertMeterToMiles(radius) {
        var miles = (radius * 0.621371) / 1000;
        return this.closest(miles, [0.25, 0.5, 1, 2, 3, 4, 5, 10, 15]);
    }

    closest(goal, arr) {
        return arr.reduce(function(prev, curr) {
            return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
        });
    }

    renderFeature(feature) {
        let response = ''
        if (feature.limits) {
            // If we have feature limits, show the details.
            switch (feature.limits.unit) {
                case 'events':
                    if (feature.limits.max === 9999) {
                        response = '<strong>Unlimited</strong> ' + feature.limits.unit + ' <span class="period">per month</span>'
                    } else {
                        response = 'Up to <strong>' + feature.limits.max + '</strong> ' + feature.limits.unit + ' <span class="period">per month</span>'
                    }
                    break;
                case 'meters':
                    response = 'Up to <strong>' + this.convertMeterToMiles(feature.limits.max) + '</strong> mile radius'
                    break;
                case 'shoppers':
                    response =  'Up to <strong>' + feature.limits.max + '</strong> ' + feature.limits.unit + ' to unlock'
                    break;
                case 'hours':
                    response =  'Up to <strong>' + feature.limits.max + '</strong> ' + feature.limits.unit + ' <span class="period">per event</span>'
                    break;
                case 'coupons':
                    response =  'Up to <strong>' + feature.limits.max + '</strong> ' + feature.limits.unit + ' <span class="period">per event</span>'
                    break;
                default:
                    response =  '<strong>' + feature.limits.max + '</strong> ' + feature.limits.unit
                    ;
            }
        } else {
            response = feature.name
        }
        return response
    }

    renderButton(plan) {

        const { pipioJSON, canceled } = this.state;

        if (!canceled && (pipioJSON.subscription === plan.id) && (moment.utc(pipioJSON.subscription_expiration) > moment.utc())) {
            // Show Cancel
            return <Button className="btn btn-primary btn-block mb-2" variant="primary" onClick={() => { this.cancelSubscription() }} type="submit">Cancel</Button>

        } else {
            if (canceled && pipioJSON.subscription === plan.id) {
                // Show Re-activate
                return <Button className="btn btn-primary btn-block mb-2" onClick={() => {
                    this.submitReActivatePlan(plan.id)
                }} type="submit">Re-activate</Button>
            } else {
                if (!canceled && pipioJSON.subscription === plan.id) {
                    // Show Active plan
                    return <Button className="btn btn-primary btn-block mb-2">Active</Button>
                } else {
                    if (!canceled || pipioJSON.subscription === plan.id) {
                        return <Button className="btn btn-primary btn-block mb-2"
                            onClick={() => {
                            this.submitChosenPlan(plan.id)
                        }} type="submit">Select this plan</Button>
                    }
                }
            }
        }

    }

    _onIdle(e) {

        if (this.stopIdleTimer)
            this.idleTimer.pause()
        else {
            setMerchantAuthToken(getAuthToken())
            logout(response => {
                storeAuthToken("")
                return this.props.history.push('/MerchantSignIn');
            });
        }

    }

    render() {

        const { plans, sale_types, event_types, pipioJSON, showConfirmCancel, has_subscription, canceled, date_expiry } = this.state;
        const fromSignIn = this.props.history.location.state ? this.props.history.location.state.fromSignIn : null;

        const styles = {
          bubble: {
            position: 'absolute',
            top: '-85px',    // computed based on child and parent's height
            right: '50px',   // computed based on child and parent's width
            zIndex: 100,
          },
        };

        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    debounce={250}
                    onIdle={this.onIdle}
                    timeout={1000 * this.seconds } />
                {
                    <div>
                        {(!fromSignIn || !has_subscription) ? (
                            <Header title="Merchant setup - Choose your plan" history={this.props.history}
                                    pipioJSON={this.state.pipioJSON}
                                    isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null}/>
                        ) : (
                            <Header title={pipioJSON ? pipioJSON.business_name + " - Change your plan" : null}
                                    history={this.props.history} pipioJSON={this.state.pipioJSON}
                                    isRegistration={this.props.history.location.state ? this.props.history.location.state.isRegistration : null}/>
                        )}
                        <section className="pricing bg-secondary py-6">
                            <ModalCustom show={showConfirmCancel} title="Subscription"
                                         body={this.state.msg}
                                         onHide={() => {
                                             this.setState({showConfirmCancel: false})
                                         }}
                                         button1Caption={"Ok"}
                                         button1Action={() => {
                                             this.setState({showConfirmCancel: false})
                                         }}/>
                            <div className="container">

                                <div className="row justify-content-md-center">
                                    {plans ? (plans.items.map((plan) => (
                                        // Remove Beta plan
                                        ( plan.id !== 1 || process.env.REACT_APP_NOT_PROD === 'TRUE') ? (
                                            <div className="col-lg-4">

                                                <div className="card mb-5 mb-lg-0">
                                                    <div style={styles.bubble}>
                                                        { pipioJSON.subscription === plan.id ?
                                                        <div className="bubble thought text-center">
                                                            <p>
                                                                <strong>
                                                                Your current plan will
                                                                { canceled ? ' expire ' : ' renew ' }
                                                                on {date_expiry}
                                                                </strong>
                                                            </p>
                                                            <div className="bird-right"></div>
                                                        </div> : null}
                                                    </div>

                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-muted text-uppercase text-center">{plan.name}</h5>
                                                            <h6 className="card-price text-center">{plan.price.price_unit}{plan.price.value}<span
                                                                className="period">/{plan.price.term_unit}</span></h6>
                                                            <hr/>
                                                            <ul className="fa-ul">
                                                                {
                                                                    plan.features.map((feature) => (
                                                                        <li className={(feature.status ? '' : 'text-muted')}>
                                                                            <FontAwesomeIcon
                                                                                icon={(feature.status ? 'check' : 'times')}/>&nbsp;
                                                                            {ReactHtmlParser(this.renderFeature(feature))}
                                                                        </li>
                                                                    ))
                                                                }
                                                                <li><FontAwesomeIcon icon="check"/> <strong>Event
                                                                    Types:</strong></li>
                                                                <ul className="fa-ul sub-ul">
                                                                    {
                                                                        plan.event_types.map((value) => (
                                                                            <li>
                                                                                <FontAwesomeIcon
                                                                                    icon="asterisk"/> {event_types[value]}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                                <li><FontAwesomeIcon icon="check"/> <strong>Sale
                                                                    Types:</strong></li>
                                                                <ul className="fa-ul sub-ul">
                                                                    {
                                                                        plan.sale_types.map((value) => (
                                                                            <li>
                                                                                <FontAwesomeIcon
                                                                                    icon="asterisk"/> {sale_types['items'][value]['description']}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </ul>

                                                            <div className="text-center">
                                                                {this.renderButton(plan)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    ))) : null}
                                </div>
                            </div>
                        </section>

                        <Footer/>
                    </div>
                }
            </div>
        )
    }
}

export default MerchantSubscription;
